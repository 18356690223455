import React, {useCallback, useEffect, useState} from 'react';
import './ChatMainMenu.css';
import Tooltip from '@mui/material/Tooltip';
import {useNavigate} from "react-router-dom";
import {CharacterDeleteForm} from "../CharacterDeleteForm";
import {getUserAccessToken, getUserID, userIDExists} from "../../../App";
import {ReactComponent as SearchIcon} from '../../../icons/search-chat.svg';
import {ReactComponent as CloseMainMenu} from "../../../icons/message/close-the-menu.svg";
import 'react-lazy-load-image-component/src/effects/blur.css';
import RecentChatListLoader from "../../LoadingEvents/RecentChatListLoader";
import {useTimer} from "../../Context/TimerContext";
import {useChat} from "../../Context/ChatContext";
import debounce from 'lodash/debounce';
import CustomizationModal from "../CustomizationScreen/CustomizationModal";
import {useTranslation} from "react-i18next";
import MobileRecentChatItem from "./MobileRecentChatItem";
import DesktopRecentChatItem from "./DesktopRecentChatItem";
import {MobileNavigation} from "../../MainMenuPage/MobileNavigation";
import {useChatSettings} from "../../../pages/ChatPage/ChatSettingsContext";
import i18n from "i18next";

const RecentChatList = ({
                            isLoading,
                            setLocalChats,
                            localChats,
                            profileUsername,
                            setCurrentCharName,
                            chatContainerRef,
                            setShowChatMainMenu,
                            isMobile,
                            setLlmModel,
                            setMaxTokens,
                            setTemperature,
                            setResponseMode,
                            // setNsfwEnabled,
                            setDynamicScenarioEnabled,
                            clientUpdateData,
                            setIsOwnerBoolean,
                            deleteCurrentChat,
                            setDeleteCurrentChat,
                            setSelectedLanguage,
                            setIsSimple,
                            setIsPublic,
                            setFirstMessageAudio
                        }) => {
    const navigate = useNavigate();
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isDeletionFormOpen, setIsDeletionFormOpen] = useState(false);
    const [deletionData, setDeletionData] = useState([]);

    const handleDeleteConfirmation = (chat) => {
        setDeletionData(chat);
        setIsDeletionFormOpen(true);
    };

    const {
        isMenuOpen,
        toggleMenu,
        setIsActiveModal,
        setWasRecentChatUpdated
    } = useChat();

    const {
        isCustomizeModalOpen,
        toggleCustomizeModal
    } = useChatSettings();

    useEffect(() => {
        if (isCustomizeModalOpen) {
            setIsActiveModal(true);
        }

        return () => {
            if (isCustomizeModalOpen) {
                setIsActiveModal(false);
            }
        };
    }, [isCustomizeModalOpen, setIsActiveModal]);


    const useDebounceRender = (initialState = false, delay = 50) => {
        const [isRendering, setIsRendering] = useState(initialState);
        const [shouldRender, setShouldRender] = useState(initialState);
//eslint-disable-next-line react-hooks/exhaustive-deps
        const debouncedSetShouldRender = useCallback(
            debounce(setShouldRender, delay),
            [delay]
        );

        useEffect(() => {
            if (isRendering) {
                debouncedSetShouldRender(true);
            } else {
                setShouldRender(false);
                debouncedSetShouldRender.cancel();
            }
        }, [isRendering, debouncedSetShouldRender]);

        return [shouldRender, setIsRendering];
    };

    const [shouldRender, setIsRendering] = useDebounceRender();

    const {
        setDescription, setScenario, setProfileURLBanner, setActiveComponent,
    } = useChat();

    useEffect(() => {
        setActiveComponent('RecentChatList');
        return () => {
            setActiveComponent('');
        };
    }, [setActiveComponent]);

    useEffect(() => {
        if (clientUpdateData === null || clientUpdateData === undefined) {
            return;
        }
        const filteredChatsCopy = filteredChats;
        for (let i = 0; i < filteredChatsCopy.length; i++) {
            if (filteredChatsCopy[i].identityName === clientUpdateData.identityName) {
                filteredChatsCopy[i].lastMessageTime = clientUpdateData.newTimestamp;
                filteredChatsCopy[i].lastMessageContent = clientUpdateData.finalMessage;
            }
        }
        const sortedByTimestamp = filteredChatsCopy.sort((a, b) => {
            const dateA = new Date(a.lastMessageTime);
            const dateB = new Date(b.lastMessageTime);
            return dateB - dateA;
        });
        setFilteredChats(sortedByTimestamp);

        // eslint-disable-next-line
    }, [clientUpdateData]);

    useEffect(() => {
        const characterIdentity = window.location.pathname.substring(6);
        setTimeout(() => {
        }, 1000);
        renderChats(characterIdentity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    useEffect(() => {
        if (deleteCurrentChat === true) {
            setDeleteCurrentChat(false);
            deleteChat(selectedChat);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCurrentChat]);

    const deleteChat = (chat) => {
        setChats(chats.filter((c) => c !== chat));
        setLocalChats(chats.filter((c) => c !== chat));
        const x = chats.filter((c) => c !== chat);
        setIsDeletionFormOpen(false);
        setDeletionData([]);
        // No network based rendering here
        setTimeout(() => {
            if (x.length > 0) {
                handleChatSelection(x[0]);
            } else {
                navigate("/chat")
                setSelectedChat(null);
                // setIdentityName("");
            }
        }, 100);
    }


    const renderChats = async (identity) => {

        setIsRendering(true);
        try {
            const exists = await userIDExists();
            if (!exists) {
                setIsRendering(false);
                return;
            }

            const id = await getUserID();
            const language = i18n.language;

            let chatsData;
            // Use existing chats if available
            if (localChats.length > 0) {
                chatsData = localChats;
            } else {
                // Fetch chats if not available locally
                const response = await fetch('https://api.elyza.app/v1/recent-chat', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: await getUserAccessToken(),
                    },
                    body: JSON.stringify({
                        userId: id,
                        language: language ?? 'en',
                    })
                });

                const data = await response.json();
                setWasRecentChatUpdated(true);
                chatsData = data.filter(item => item.identityName !== undefined);
            }

            updateChatsAndSelection(chatsData, identity);

        } catch (error) {
            console.error('Error in renderChats:', error);
        } finally {
            setIsRendering(false);
        }
    };

    const updateChatsAndSelection = (chatsData, identity) => {
        const selectedChat = chatsData.find(chat => chat.identityName === identity);

        if (selectedChat) {
            setIsOwnerBoolean(selectedChat.isOwner === true);
            setSelectedChat(selectedChat);
            handleChatSelection(selectedChat);

            // Update character info
            const {description, characterScenario, imageSrc} = selectedChat;
            setDescription(description);
            setScenario(characterScenario);
            setProfileURLBanner(imageSrc);
        } else if (chatsData.length > 0) {
            handleChatSelection(chatsData[0]);
        }

        setChats(chatsData);
        setLocalChats(chatsData);
    };


    const handleChatSelection = (chat, isUserSelection = false) => {

        const currentState = window.history.state?.usr?.autoSelect;

        // If mobile and autoSelect is true, bypass regular mobile restrictions
        if (isMobile && !currentState) {
            if (!isUserSelection && chat !== selectedChat) {
                return;
            }
            if (isLoading && selectedChat === undefined) {
                return;
            }
        } else if (isLoading && !currentState) {
            return;
        }

        setCurrentCharName(chat.name);

        navigate(`/chat/${chat.identityName}`)
        setSelectedChat(chat);

        if (currentState && isMobile) {
            setShowChatMainMenu(true);
        }

        if (chat.llmModel !== undefined) {
            setLlmModel(chat.llmModel);
        }
        if (chat.maxTokens !== undefined) {
            setMaxTokens(chat.maxTokens);
        }
        if (chat.isSimple !== undefined) {
            setIsSimple(chat.isSimple);
        }
        if (chat.isPublic !== undefined) {
            setIsPublic(chat.isPublic);
        }
        if (chat.temperature !== undefined) {
            setTemperature(chat.temperature);
        }
        if (chat.responseMode !== undefined) {
            setResponseMode(chat.responseMode);
        }
        if (chat.dynamicScenario !== undefined) {
            setDynamicScenarioEnabled(chat.dynamicScenario);
        }
        if (chat.language !== undefined) {
            setSelectedLanguage(chat.language);
        }
        if (chat.firstMessageAudio !== undefined) {
            setFirstMessageAudio(chat.firstMessageAudio)
        }

        scrollToBottom();

        if (chatContainerRef.current) {
            const images = chatContainerRef.current.getElementsByTagName('img');
            Array.from(images).forEach((img) => {
                if (img.complete) {
                    // Image is not yet loaded, attach an event listener
                    img.addEventListener('load', scrollToBottom);
                    img.addEventListener('error', scrollToBottom); // Handle broken images too
                }
            });
        }

        setShowChatMainMenu(true);

    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (chatContainerRef.current) {
                chatContainerRef.current.style.scrollBehavior = 'smooth';
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                chatContainerRef.current.style.scrollBehavior = 'auto';
            }
        }, 1000);
    }

    const truncateMessage = (message, limit = 50, charName = "", chatIdentityName) => {
        if (!message) {
            return "Unknown message";
        }

        // Check if a draft exists in localStorage for the specific character
        const draftMessage = localStorage.getItem(`message-${chatIdentityName}`);
        if (draftMessage) {
            message = `*Draft:* ${draftMessage}`;
        }

        message = message.replace(/\*/g, "");
        message = message.replace(/{{char}}/g, charName)
            .replace(/{{user}}/g, profileUsername)
            .replace(/{char}/g, charName)
            .replace(/{user}/g, profileUsername)
            .replace(/{Char}/g, charName)
            .replace(/{User}/g, profileUsername);
        return message.length > limit ? message.substring(0, limit) + '...' : message;
    };

    function formatTimeAgo(dateTime) {
        // Check for null or invalid date
        if (!dateTime || isNaN(Date.parse(dateTime))) {
            return "Unknown time";
        }

        const messageDate = new Date(dateTime);
        const now = new Date();
        const differenceInSeconds = Math.floor((now - messageDate) / 1000);
        const minute = 60;
        const hour = 3600;
        const day = 86400;
        const week = 604800;
        const month = 2628000;

        if (differenceInSeconds < minute) {
            return `${differenceInSeconds}s ago`;
        } else if (differenceInSeconds < hour) {
            return `${Math.floor(differenceInSeconds / minute)}m ago`;
        } else if (differenceInSeconds < day) {
            return `${Math.floor(differenceInSeconds / hour)}h ago`;
        } else if (differenceInSeconds < week) {
            return `${Math.floor(differenceInSeconds / day)}d ago`;
        } else if (differenceInSeconds < week * 12) {
            const weeksAgo = Math.floor(differenceInSeconds / week);
            if (weeksAgo < 1) {
                return `${Math.floor(differenceInSeconds / day)}d ago`;
            }
            return `${weeksAgo}w ago`;
        } else {
            const monthsAgo = Math.floor(differenceInSeconds / month);
            if (monthsAgo < 12) {
                return `${monthsAgo}m ago`;
            } else {
                const yearsAgo = Math.floor(monthsAgo / 12);
                return `${yearsAgo}y ago`;
            }
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredChats, setFilteredChats] = useState(chats);

    useEffect(() => {
        if (searchTerm !== '') {
            const lowercasedSearchTerm = searchTerm.toLowerCase();
            const filteredAndSortedChats = chats
                .filter(chat => chat.name && chat.name.toLowerCase().includes(lowercasedSearchTerm))
                .sort((a, b) => a.name.localeCompare(b.name));
            setFilteredChats(filteredAndSortedChats);
        } else {
            const sortedByTimestamp = [...chats].sort((a, b) => {
                const dateA = new Date(a.lastMessageTime);
                const dateB = new Date(b.lastMessageTime);
                return dateB - dateA;
            });
            setFilteredChats(sortedByTimestamp);
        }
    }, [searchTerm, chats]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const [showNoCharacterModal, setShowNoCharacterModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (chats.length === 0) {
                setShowNoCharacterModal(true);
            } else {
                setShowNoCharacterModal(false);
            }
        }, 2000);

        // Cleanup function to clear the timeout if the effect re-runs or the component unmounts
        return () => clearTimeout(timer);
    }, [chats]); // Dependency array, re-run the effect if 'ch

    const {timerExpired} = useTimer();

    function setVH() {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    window.addEventListener('resize', setVH);
    setVH(); // Set the value on initial load

    const {t} = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <div className={`recent-chat-list-container-h ${isMenuOpen ? 'open' : 'closed'}`}
                 style={{
                     zIndex: isMenuOpen === true ? '1' : '1',
                     // height: timerExpired !== false ? '100vh' : 'calc(100vh - 40px)',
                 }}
            >
                {shouldRender ? (
                    <div
                        className="recent-chat-loader"
                        style={{top: timerExpired !== false ? '85px' : '145px'}}
                    >
                        <RecentChatListLoader/>
                    </div>
                ) : null}

                <div className="recent-chat-list-container"
                     style={{
                         marginTop: timerExpired !== false ? '85px' : '145px',
                         height: timerExpired !== false
                             ? 'calc(var(--vh, 1vh) * 100 - 85px)'
                             : 'calc(var(--vh, 1vh) * 100 - 145px)',
                         '--vh': '1dvh',
                     }}
                >
                    {showNoCharacterModal && (
                        <div className="empty-modal">
                            <div className="empty-modal-content"
                                 style={{marginTop: timerExpired !== false ? '80px' : '100px'}}>
                                <span className="feature-icon-text">{t('emptyState')}</span>
                                <br></br>
                                <span>{t('chooseOption')}</span>
                                <div className='a-straight-line' style={{marginTop: '20px', marginBottom: '5px'}}></div>
                                <div className="button-bottom-no-chars-section">
                                    <button
                                        className='button-no-chars'
                                        onClick={() => {
                                            setShowNoCharacterModal(false);
                                            navigate('/');
                                        }}
                                    >
                                        {t('findOne')}
                                    </button>
                                    <button
                                        className='button-no-chars'
                                        onClick={() => {
                                            setShowNoCharacterModal(false);
                                            navigate('/create-character'); // Navigate to Create Character
                                        }}
                                    >
                                        {t('createOne')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="chat-navigation-control-menu">
                        <div className="top-section">
                            <span className="recent-chat-list-title">{t('chats')}</span>

                            <div className="settings-icons">

                                {!isMobile && (
                                    <Tooltip title={t('CloseMenu')} placement="top" arrow>
                                        <CloseMainMenu onClick={toggleMenu}/>
                                    </Tooltip>
                                )}
                            </div>


                        </div>
                        <p className="smaller-text">{t('chatOptionsDescription')}</p>
                        <div className="search-container-chatpage">
                            <SearchIcon/>
                            <input
                                className="chat-search-input"
                                type="text"
                                placeholder={t('searchChats')}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className="recent-chat-sidebar-menu">
                        {filteredChats.map((chat, index) => (
                            windowWidth >= 680 ? (
                                <DesktopRecentChatItem
                                    key={chat.identityName}
                                    chat={chat}
                                    isSelected={chat === selectedChat}
                                    onDelete={handleDeleteConfirmation}
                                    onSelect={(chat) => handleChatSelection(chat, true)}
                                    formatTimeAgo={formatTimeAgo}
                                    truncateMessage={truncateMessage}
                                    profileUsername={profileUsername}
                                />
                            ) : (
                                <MobileRecentChatItem
                                    key={chat.identityName}
                                    chat={chat}
                                    isSelected={chat === selectedChat}
                                    onDelete={handleDeleteConfirmation}
                                    onSelect={(chat) => handleChatSelection(chat, true)}
                                    formatTimeAgo={formatTimeAgo}
                                    truncateMessage={truncateMessage}
                                    profileUsername={profileUsername}
                                    isLastItem={index === filteredChats.length - 1}
                                />
                            )
                        ))}

                    </div>
                </div>
            </div>

            <CustomizationModal
                isOpen={isCustomizeModalOpen}
                onCancel={toggleCustomizeModal}
            />

            <CharacterDeleteForm
                deleteCall={deleteChat}
                chat={deletionData}
                isOpen={isDeletionFormOpen}
                close={setIsDeletionFormOpen}
            />

            <MobileNavigation/>
        </>
    );
}
export default RecentChatList;