import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {supabase, getUserAccessToken} from '../../App';
import {ReactComponent as DiscordIcon} from '../../icons/discord.svg';
import {ReactComponent as GoogleIcon} from '../../icons/google.svg';
import {ReactComponent as CloseModalIcon} from '../../icons/cross.svg';
import ResetPasswordPage from './ResetPassword.js';
import {Turnstile} from '@marsidev/react-turnstile'
import './AuthModal.css';
import {useTranslation} from "react-i18next";
import i18n from "i18next";

import {ReactComponent as TwitterIcon} from "../../icons/twitter.svg";

export const AuthModal = ({onClose}) => {
    const [isSignUp, setIsSignUp] = useState(true); // false means login, true means signup
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [captchaToken, setCaptchaToken] = useState()
    const captchaRef = useRef();

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    };

    useEffect(() => {

        const path = window.location.pathname;
        if (path === '/logout') {

            const genderPreference = localStorage.getItem('genderPreference');
            localStorage.clear();

            if (genderPreference) {
                // Restore genderPreference since it was cleared with everything else
                localStorage.setItem('genderPreference', genderPreference);
            }


            supabase.auth.signOut().then(() => {

            });
        }
    }, []);

    const handleAuthSwitch = () => {
        setError(""); // Clear any errors
        setIsSignUp(!isSignUp); // Switch mode
    };

    const isValidEmail = (email) => {
        return /^[^\s@+]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidPassword = (password) => {
        const minLength = 8;

        return password.length >= minLength;
    };

    const handleSignUp = async (event) => {
        event.preventDefault();

        if (!email || !isValidEmail(email)) {
            setEmailError(true);
            setError("Please enter a valid email address.");
            setCaptchaToken(null);
            return;
        } else {
            setEmailError(false);
        }

        if (!password || !isValidPassword(password)) {
            setPasswordError(true);

            setError("Password must be at least 8 characters long!");
            setCaptchaToken(null);
            return;
        } else {
            setPasswordError(false);
        }

        window.rdt('track', 'SignUp');

        setError("");
        setIsLoading(true);
        try {

            const response = await supabase.auth.signUp({
                email, password, options: {captchaToken}
            });
//TODO - Add user gender preference into storage
            if (response.error) {
                setError(response.error.message);
                setCaptchaToken(null);
                return;
            } else {
                if (response.data.user) {
                    const id = response.data.user.id;
                    await fetch('https://api.elyza.app/v1/update-profile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': await getUserAccessToken()
                        },
                        body: JSON.stringify({
                            userId: id,
                            imageBase64: "",
                            newUsername: name.length > 0 ? name : "Guest",
                            // newBio: "",
                            genderPreference: localStorage.getItem('genderPreference') ?? "Female",
                            justRegistered: true,
                            affCode: localStorage.getItem('affCode') ?? "",
                            referralCode: localStorage.getItem('referralCode') ?? "",
                            campaignCode: localStorage.getItem('campaignCode') ?? "",
                            targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                            language: languageCodeToEnglishName[i18n.language] ?? "English",
                        })
                    });

                    onClose();
                    window.location.reload();
                }
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleLogin = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setIsLoading(true);
        try {

            const {error} = await supabase.auth.signInWithPassword({email, password, options: {captchaToken}});
            if (error) {
                setErrorMessage(error.message);
                setCaptchaToken(null);
            } else {
                onClose(); // Close the login modal or reset the login state
                window.location.reload(); // Reload the page
            }
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleGoogleSignIn = async () => {
        try {
            //eslint-disable-next-line
            const {user, session, error} = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location}`,
                },
                queryParams: {
                    access_type: 'offline',
                    prompt: 'consent',
                }
            });
            // console.log('Redirecting to:', window.location)
            if (error) throw error;
            // console.log('Google sign-in successful:', user, session);

        } catch (error) {
            // console.error('Google sign-in error:', error);
        }
    };

    const handleDiscordSignIn = async () => {
        try {
            // Note: redirectTo is part of the options object for the provider.
            //eslint-disable-next-line
            const {user, session, error} = await supabase.auth.signInWithOAuth({
                provider: 'discord',
                options: {
                    redirectTo: `${window.location}`,
                },
            });

            if (error) throw error;
            // console.log('Discord sign-in successful:', user, session);
        } catch (error) {
            // console.error('Discord sign-in error:', error);
            setError(error.message); // Ensure setError is defined and can manage component state
        }
    };

    const handleTwitterSignIn = async () => {
        try {
            // Note: redirectTo is part of the options object for the provider.
            //eslint-disable-next-line
            const {user, session, error} = await supabase.auth.signInWithOAuth({
                provider: 'twitter',
                options: {
                    redirectTo: `${window.location}`,
                },
            });

            if (error) throw error;
        } catch (error) {
            setError(error.message); // Ensure setError is defined and can manage component state
        }
    }


    const AuthRef = useRef(null);
    useEffect(() => {
        // Function to call onClose if the click is outside the modal
        const handleClickOutside = (event) => {
            if (AuthRef.current && !AuthRef.current.contains(event.target)) {
                onClose(); // Call your onClose function if the click is outside
            }
        };

        // Attach the event listener to the document
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener from the document when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);


    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const handleForgotPassword = () => {
        setShowResetPasswordModal(true); // Show the reset password modal
    };

    const { t } = useTranslation();

    return (
        <div className="profile-edit-overlay">
            <div className="auth-modal" ref={AuthRef}>
                <div className="auth-container">
                    {isSignUp ? (
                        <>
                            <div className="signup-form">
                                <div className="main-upper-text-row">
                                    <div className="main-elyza-text-label">{t('LetsGetStarted')}</div>

                                    <div className="close-modal-icon" onClick={onClose}><CloseModalIcon/></div>
                                </div>
                                <div className="main-elyza-text-sublabel">{t('SignUpSublabel')}</div>

                                <div className="a-straight-line social-signup-divider"></div>
                                <div className="social-signup-container">
                                    <button className="social-signup-button google" onClick={handleGoogleSignIn}>
                                        <GoogleIcon className="social-signup-button-icon-google"/>
                                        <span className="social-signup-button-text">{t('SignUpWithGoogle')}</span>

                                    </button>
                                    <div className="double-social-signup-button">
                                        <button className="social-signup-button twitter" onClick={handleTwitterSignIn}>
                                            <TwitterIcon className="social-signup-button-icon-discord"/>
                                            <span className="social-signup-button-text"></span>
                                        </button>
                                        <button className="social-signup-button discord" onClick={handleDiscordSignIn}>
                                            <DiscordIcon className="social-signup-button-icon-discord"/>
                                            <span className="social-signup-button-text">{t('LoginWithDiscord')}</span>
                                        </button>
                                    </div>
                                </div>
                                <form onSubmit={handleSignUp}>
                                    <div className="or-container">
                                        <div className="line"></div>
                                        <span className="or-label">{t('OR')}</span>
                                        <div className="line"></div>
                                    </div>

                                    {/*TODO figure out if we need this*/}
                                    <div className={`textbox-name`}>
                                        <label className="label-sign-up-h">{t('Username')}</label>

                                        <input
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Username"
                                        />
                                    </div>

                                    <div className={`textbox-email ${emailError ? 'shake-animation' : ''}`}>
                                        <label className="label-sign-up-h">{t('Email')}</label>

                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="support@elyza.app"  // Added placeholder attribute
                                        />
                                    </div>

                                    <div className={`textbox-password ${passwordError ? 'shake-animation' : ''}`}>
                                        <label className="label-sign-up-h">{t('Password')}</label>
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="●●●●●●●●●"  // Added placeholder attribute
                                        />
                                    </div>

                                    <button className='signup-button' onClick={handleSignUp}
                                            disabled={!name.length || !email.length || !password.length || isLoading || !captchaToken}>
                                        {t('SignUp')}
                                    </button>

                                    {!captchaToken ?
                                        <Turnstile
                                            ref={captchaRef}
                                            style={{
                                                display: "none",
                                            }}
                                            siteKey="0x4AAAAAAAWnS3GcUrv9riNn"
                                            onSuccess={(token) => {
                                                setCaptchaToken(token);
                                            }}
                                        /> : null
                                    }

                                    {error && <div className="error-message">{error}</div>}
                                    <div className="tos">{t('TOSMessage')} <Link to="/legal/terms-of-use" className="underline">{t('TermsOfUse')}</Link></div>

                                    {/*{isLoading && <div>Loading...</div>}*/}

                                    <div style={{marginTop: '15px'}} className="a-straight-line"></div>

                                    <button onClick={handleAuthSwitch}
                                            className="popup-link popup-link-bottom">
                                        {t('AlreadyHaveAccount')}
                                        <span className="underline">{t('Login')}</span>
                                    </button>
                                </form>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="login-container">
                                <div className="main-upper-text-row">
                                    <div className="main-elyza-text-label">{t('WelcomeBack')}</div>
                                    <div className="close-modal-icon" onClick={onClose}><CloseModalIcon/></div>
                                </div>
                                <div className="main-elyza-text-sublabel">{t('LoginSublabel')}</div>

                                <div className="a-straight-line social-signup-divider"
                                     style={{marginTop: '10px'}}
                                ></div>
                                <div className="social-signup-container">
                                    <button className="social-signup-button google" onClick={handleGoogleSignIn}>
                                        <GoogleIcon className="social-signup-button-icon-google"/>
                                        <span className="social-signup-button-text">{t('LoginWithGoogle')}</span>

                                    </button>

                                    <div className="double-social-signup-button">

                                        <button className="social-signup-button twitter" onClick={handleTwitterSignIn}>
                                            <TwitterIcon className="social-signup-button-icon-discord"/>
                                            <span className="social-signup-button-text"></span>
                                        </button>

                                        <button className="social-signup-button discord" onClick={handleDiscordSignIn}>
                                            <DiscordIcon className="social-signup-button-icon-discord"/>
                                            <span className="social-signup-button-text">{t('LoginWithDiscord')}</span>
                                        </button>

                                    </div>

                                </div>
                                <div className="or-container"
                                     style={{marginBottom: '-10px'}}
                                >
                                    <div className="line"></div>
                                    <span className="or-label">{t('OR')}</span>
                                    <div className="line"></div>
                                </div>
                                <form onSubmit={handleLogin}>
                                    <div className="textbox-email">
                                        <label className="text-field-header">{t('Email')}</label>
                                        <input
                                            type="email"
                                            id="login-email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="support@elyza.app"
                                            className="textbox-emailinput"
                                        />
                                    </div>
                                    <div className="textbox-password">
                                        <label className="text-field-header">{t('Password')}</label>
                                        <input
                                            type='password'
                                            id="login-password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="●●●●●●●●●"
                                            className="textbox-passwordinput"
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="signup-button"
                                        onClick={handleLogin}
                                        disabled={!email.length || !password.length || isLoading || !captchaToken}
                                    >
                                        {t('Login')}
                                    </button>
                                    {!captchaToken ?
                                        <Turnstile
                                            ref={captchaRef}
                                            style={{
                                                display: "none",
                                            }}
                                            siteKey="0x4AAAAAAAWnS3GcUrv9riNn"
                                            onSuccess={(token) => {
                                                setCaptchaToken(token);
                                            }}
                                        /> : null
                                    }

                                    {errorMessage && <div className="error-message">{errorMessage}</div>}

                                </form>
                                <div className="a-straight-line-2"></div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <button onClick={handleAuthSwitch}
                                            className="popup-link popup-link-bottom">
                                        {t('DontHaveAccount')}
                                        <span className="underline">{t('SignUp')}</span>
                                    </button>

                                    <button onClick={handleForgotPassword}
                                            className="popup-link">
                                        {t('ForgotPassword')}
                                        <span className="underline">{t('ResetIt')}</span>
                                    </button>
                                </div>
                                <ResetPasswordPage
                                    show={showResetPasswordModal}
                                    onHide={() => setShowResetPasswordModal(false)}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};