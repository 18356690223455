import React, {useEffect, useState} from 'react';
import '../../components/Notifications/ModalAndNotifications.css';
import './CancellationModal.css';
import {ReactComponent as CloseIcon} from "../../components/MainMenuPage/icons/cross.svg";
import {ReactComponent as ChatIcon} from "./files/ChatIcon.svg";
import {ReactComponent as MemoryIcon} from "./files/MemoryIcon.svg";
// import {useTranslation} from "react-i18next";
import {useProfileData} from "../../components/Context";
import {getUserAccessToken, getUserID} from "../../App";

const CancellationModal = ({isOpen, onClose, continueCancellation}) => {

    // const {t} = useTranslation();

    const {profileData} = useProfileData();

    const [messages, setMessages] = useState('0');
    const [images, setImages] = useState('0');
    const [audios, setAudios] = useState('0');
    const [streakStats, setStreakStats] = useState(0);
    const [premiumDays, setPremiumDays] = useState(0);

    async function getUsageStats() {
        try {
            const response = await fetch('https://api.elyza.app/v1/usage-counter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: await getUserAccessToken(),

                },
                body: JSON.stringify({
                    user_id: await getUserID()
                })
            });
            const data = await response.json();
            return data.streak;
        } catch (error) {
            console.error('Error fetching usage stats:', error);
            return 0;
        }
    }

    useEffect(() => {
        if (profileData) {
            // Fetch usage stats
            const fetchStats = async () => {
                const stats = await getUsageStats();
                // Extract the streak value from the response object
                if (stats && stats.success && stats.task) {
                    setStreakStats(stats.task); // Assuming 'task' contains the streak count
                } else {
                    setStreakStats(0); // Default value if data structure is unexpected
                }
            };
            
            fetchStats();
            
            // Set other stats from profileData
            setImages(profileData.image_count);
            setAudios(profileData.audio_count);
            setMessages(profileData.message_count);

            if (profileData.subscription_data && 
                profileData.subscription_data.nextRenewalTimestamp && 
                profileData.subscription_data.lastPaymentTimestamp) {
                const nextRenewalDate = new Date(profileData.subscription_data.nextRenewalTimestamp);
                const lastPaymentDate = new Date(profileData.subscription_data.lastPaymentTimestamp);
                const diffTime = nextRenewalDate.getTime() - lastPaymentDate.getTime();
                setPremiumDays(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
            }
        }
    }, [profileData]);

    if (!isOpen && !profileData) return null;

    return (
        <div className="modal-backdrop"
            style={{zIndex: 10001}}
        >
            <div className="modal-content-notification"
                 style={{minWidth: '520px', maxWidth: '540px'}}
            >
                <div className='notification-label'>
                    Wait! Are you sure?
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    There's a lot in your subscription that you might not even be
                    considering, so here's an overview of it all.
                </div>

                <div className="consider-main-component">

                    <div className="consider-left-column">

                        <div className="consider-block">
                            <span className="consider-text top">Advanced Chat Models
                              <ChatIcon/>
                            </span>
                        </div>

                        <div className="consider-double">
                            <div className="consider-block half">
                                <span className="consider-text top">{images} images</span>
                                <span className="consider-text bottom">that you created</span>
                            </div>
                            <div className="consider-block half">
                                <span className="consider-text top">{audios} audios</span>
                                <span className="consider-text bottom">that you received</span>
                            </div>
                        </div>

                        <div className="consider-block">
                            <span className="consider-text top">Enhanced Memory System
                                <MemoryIcon/>
                            </span>
                        </div>

                        <div className="consider-double">
                            <div className="consider-block half">
                                {streakStats > 0 ? (
                                    <>
                                        <span className="consider-text top">{streakStats} day</span>
                                        <span className="consider-text bottom">streak using Elyza</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="consider-text top">Your journey</span>
                                        <span className="consider-text bottom">is just beginning!</span>
                                    </>
                                )}
                            </div>
                            <div className="consider-block half">
                                <span className="consider-text top">{premiumDays} days</span>
                                <span className="consider-text bottom">of premium benefits</span>
                            </div>
                        </div>
                    </div>

                    <div className="consider-right-column">
                        <div style={{height: '20%'}} className="consider-block height">
                            <span className="consider-text top">Priority Support</span>
                        </div>
                        <div style={{height: '33%'}} className="consider-block height">
                            <span className="consider-text top">{messages} messages</span>
                            <span className="consider-text bottom">that you sent</span>
                        </div>
                        <div style={{height: '25%'}} className="consider-block height">
                            <span className="consider-text top">24/7 Support</span>
                        </div>
                        <div style={{height: '23%'}} className="consider-block height">
                            <span className="consider-text top">{'10'} hours</span>
                            <span className="consider-text bottom">spent on Elyza</span>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px'}}>
                <button onClick={continueCancellation}
                            style={{width: '50%', backgroundColor: '#656565'}}
                            className="notification-button-main">Cancel
                    </button>
                    
                    <button onClick={onClose} className="notification-button-main">Go Back</button>
                </div>
            </div>
        </div>);
};

export default CancellationModal;