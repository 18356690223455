import React, { useCallback, useEffect, useRef, useState } from "react";
import { useWavesurfer } from "@wavesurfer/react";
import { ReactComponent as PlayButton } from "./playicon.svg";
import { ReactComponent as PauseButton } from "./pause.svg";
import BeatLoader from "react-spinners/BeatLoader";

const WaveSurferCharSample = ({ audioURL }) => {
    const [isLoading, setIsLoading] = useState(true);
    const WaveContainerRef = useRef(null);

    const { wavesurfer, isPlaying } = useWavesurfer({
        container: WaveContainerRef,
        height: 50,
        waveColor: 'rgb(255,255,255)',
        progressColor: 'rgb(164,137,236)',
        url: audioURL,
        barGap: 2,
        barWidth: 4,
        barRadius: 2,
        normalize: true,
        fillParent: true,
        autoCenter: true,
    });

    const onPlayPause = useCallback(() => {
        if (wavesurfer) {
            wavesurfer.playPause();
        }
        // eslint-disable-next-line
    }, [wavesurfer, isPlaying, audioURL]);

    useEffect(() => {
        if (wavesurfer && audioURL) {
            setIsLoading(true);
            wavesurfer.load(audioURL);

            wavesurfer.on('ready', () => {
                setIsLoading(false);
            });

            wavesurfer.on('error', () => {
                setIsLoading(false);
            });

            return () => {
                wavesurfer.unAll();
            };
        }
    }, [wavesurfer, audioURL]);

    if (!audioURL) return null;

    return (
        <div className='surfer-h-container' style={{ width: '100%', padding: '16px', marginTop: '0', position: 'relative' }}>
            {isLoading ? (
                <div className="loading-overlay" style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                }}>
                    <BeatLoader color="white" size={8} margin={4} />
                </div>
            ) : null}

            <button
                onClick={onPlayPause}
                className='surfer-control-timer'
                style={{
                    margin: '16px 10px 16px 0',
                    opacity: isLoading ? 0.5 : 1,
                    pointerEvents: isLoading ? 'none' : 'auto'
                }}
            >
                {isPlaying ? <PauseButton /> : <PlayButton />}
            </button>

            <div
                ref={WaveContainerRef}
                className='central-surfer-component'
                style={{
                    width: '100%',
                    opacity: isLoading ? 0.5 : 1,
                    transition: 'opacity 0.3s ease'
                }}
            >
                <div className='main-surfer-line' />
            </div>
        </div>
    );
}

export default WaveSurferCharSample;