import React from 'react';
import '../../components/Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../components/MainMenuPage/icons/cross.svg";

const RenewalConfirmation = ({isOpen, onClose}) => {

    const handleClose = () => {
        onClose();
        window.location.reload();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" style={{zIndex: 10005}}>
            <div className="modal-content-notification">
                <div className='notification-label'>
                    Subscription Renewed
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    Your subscription has been successfully renewed.
                </div>

                <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <button 
                        onClick={handleClose} 
                        className="notification-button-main"
                        style={{width: '100%', margin: 'unset'}}
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RenewalConfirmation; 