import React, {useEffect, useState} from "react";
import i18n from "i18next";
import "./PricingPage.css";

import {getUserEmail, userIDExists, getUserAccessToken} from "../../App";
import 'react-multi-carousel/lib/styles.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {useExitIntent} from "use-exit-intent";
import {useTranslation} from "react-i18next";

import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import {MobileNavigation} from "../../components/MainMenuPage/MobileNavigation";
import {NewPricingContainer} from "../../components/MainMenuPage/PricingPage/NewPricingContainer";

import {CloseIcon, CryptoIcon, FireIcon} from "../../icons";
import {ReactComponent as AllCreditCards} from "../../icons/creditcards.svg";
import AnimeGirl from "../../components/Banners/BannerIcons/anime1.png";
import GirlReal from "../../components/Banners/BannerIcons/real1.png";

import {AuthModal} from "../../components/Forms/AuthModal";

import {useProfileData, useTimer} from "../../components/Context";

// import ReferralMenu from "../components/Banners/Referral/ReferralMenu";
import PricingToggleSlider from "./SubComponents/PricingToggleSlider";
import PricingFrequentQuestions from "./SubComponents/FaqPricing";
import './SubComponents/FaqPricing.css';

import MorePlansModal from "./MorePlansModal";

import {
    ImageIcon,
    InfinityIcon,
    MemoryIcon,
    TopTimer,
    UpsellElyzaDev,
    UpsellRandomGirl1,
    UpsellRandomGirl2
} from "../../components/Notifications";
import "../../components/Notifications/UpdatedUpsells/UpdatedUpsell.css";

import {ReactComponent as ElyzaIcon} from "../../components/RewardScreen/RewardIcons/elyzaCoin.svg";
import {useNavigate} from "react-router-dom";


const NewPricingPage = () => {

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {t} = useTranslation();

    const navigate = useNavigate();

    const additionalPricingData = [{
        heading: `${t('basicPlanHeading')}`,
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('betterMemory')}`,
            `${t('fiftyAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
        ]
    }, {
        heading: `${t('ultimatePlanHeading')}`,
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('ultimateMemory')}`,
            `${t('fiveHundredAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('earlyAccessNewFeatures')}`,
        ]
    }];

    const pricingPlans = [{
        heading: `${t('basicPlanHeading')}`,
        monthly: {
            price: "$9.99",
            originalPrice: "$12.99",
            discountLabel: "25% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Monthly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/4a25bc87-5a41-4f3d-9259-bc096d440457"
        },
        annual: {
            price: "$7.99",
            originalPrice: "$12.99",
            discountLabel: "40% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Yearly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/b512e29f-b267-448f-8ed4-d1678876f0a9"
        },
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('betterMemory')}`,
            `${t('fiftyAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
        ]
    }, {
        heading: `${t('premiumPlanHeading')}`,
        monthly: {
            price: "$14.99",
            originalPrice: "$24.99",
            discountLabel: "40% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Monthly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/04f3aace-d709-48dd-b5b5-28a0e8b0ab0f"
        },
        annual: {
            price: "$12.99",
            originalPrice: "$24.99",
            discountLabel: "50% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Yearly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/a3630279-f1ec-4438-a03a-3f2065dea6b2"
        },
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('maximumMemory')}`,
            `${t('hundredAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('earlyAccessNewFeatures')}`,
        ]
    }, {
        heading: `${t('ultimatePlanHeading')}`,
        monthly: {
            price: "$49.99",
            originalPrice: "$69.99",
            discountLabel: "30% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_gold-USD-Monthly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/795231e3-f065-46bb-9749-799e584baa79"
        },
        annual: {
            price: "$29.99",
            originalPrice: "$69.99",
            discountLabel: "60% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_gold-USD-Yearly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/42522596-d6a0-47b1-a07f-7601d49fe828"
        },
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('ultimateMemory')}`,
            `${t('fiveHundredAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('earlyAccessNewFeatures')}`,
        ]
    }];

    const [yearlyPricing, setYearlyPricing] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [premiumPlans, setPremiumPlans] = useState([]);
    const [extraAdditionalPricingData, setExtraAdditionalPricingData] = useState(additionalPricingData);
    const {profileData} = useProfileData();
    const {timerExpired, setTimerExpired} = useTimer();

    const getAvailablePlans = (currentTier) => {
        switch (currentTier) {
            case 'FREE':
                const premiumPlan = pricingPlans.find(plan => plan.heading === t('premiumPlanHeading'));
                const otherPlans = pricingPlans.filter(plan => plan.heading !== t('premiumPlanHeading'));
                return premiumPlan ? [premiumPlan, ...otherPlans] : pricingPlans;
            case 'BASIC':
                return pricingPlans.filter(plan =>
                    plan.heading === t('premiumPlanHeading') ||
                    plan.heading === t('ultimatePlanHeading')
                );
            case 'PREMIUM':
                return pricingPlans.filter(plan =>
                    plan.heading === t('ultimatePlanHeading')
                );
            case 'GOLD':
                return [];
            default:
                return pricingPlans;
        }
    };

    const [selectedPlanHeading, setSelectedPlanHeading] = useState('');

    useEffect(() => {
        const availablePlans = getAvailablePlans(profileData.subscription_data.tier);

        if (availablePlans.length > 0) {
            let defaultPlan;

            if (profileData.subscription_data.tier === 'FREE') {
                // Explicitly select Premium plan for free users
                defaultPlan = availablePlans.find(plan => plan.heading === t('premiumPlanHeading')) || availablePlans[0];
            } else {
                // For other tiers, select first available upgrade plan
                defaultPlan = availablePlans[0];
            }

            setSelectedPlan(defaultPlan);
            setPremiumPlans([defaultPlan]);
            setSelectedPlanHeading(defaultPlan.heading);
        }
        //eslint-disable-next-line
    }, [profileData.subscription_data.tier, t, i18n.language]);

    const renderPremiumPlans = () => {
        if (profileData.subscription_data.tier === 'FREE') {
            return premiumPlans.map((plan, index) => (
                <NewPricingContainer
                    key={index}
                    heading={plan.heading}
                    features={plan.features}
                    isSelected={selectedPlanHeading === plan.heading}
                    onSelect={handlePlanSelection}
                    highlight={plan.heading === t('premiumPlanHeading')} // Add highlight for Premium plan
                />
            ));
        }

        return premiumPlans.map((plan, index) => (
            <NewPricingContainer
                key={index}
                heading={plan.heading}
                features={plan.features}
                isSelected={selectedPlanHeading === plan.heading}
                onSelect={handlePlanSelection}
            />
        ));
    };

    const pricingOptions = [
        {
            key: "monthly",
            isActive: !yearlyPricing,
            discountLabel: selectedPlan ? selectedPlan.monthly.discountLabel : "40% Off!",
            price: selectedPlan ? selectedPlan.monthly.price : "$14.99",
            originalPrice: selectedPlan ? selectedPlan.monthly.originalPrice : "$24.99",
            title: t('Monthly'),
        },
        {
            key: "annual",
            isActive: yearlyPricing,
            discountLabel: selectedPlan ? selectedPlan.annual.discountLabel : "50% Off!",
            price: selectedPlan ? selectedPlan.annual.price : "$12.99",
            originalPrice: selectedPlan ? selectedPlan.annual.originalPrice : "$24.99",
            title: t('Annually'),
            mostPopular: true,
        },
    ];

    const handleAbandonedCart = async (email, packageType) => {
        // handle package transform as necessary here
        const containsPremium = packageType.includes('premium');
        const containsGold = packageType.includes('gold');

        let actualPackageType = "Basic Subscription";
        if (containsPremium) {
            actualPackageType = "Premium Subscription";
        } else if (containsGold) {
            actualPackageType = "Gold Subscription";
        }

        try {
            const response = await fetch('https://api.elyza.app/v1/handle-abandoned-cart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await userIDExists() ? await getUserAccessToken() : ""
                },
                body: JSON.stringify({
                    abandonedPackage: actualPackageType,
                    email: email
                })
            });
            return await response.json();
        } catch (error) {
            console.error('Error recording abandoned cart:', error);
        }
    };

    const renderPricingOptions = () => {
        if (profileData.subscription_data.tier === 'GOLD') {
            return (
                <div className="buy-right-now-button"
                     style={{textAlign: 'center', fontSize: '1.25rem', cursor: 'default'}}>
                    {t('YouHaveUltimate')}<br/>{t('ThankYou')}
                </div>
            );
        }

        const availablePlans = getAvailablePlans(profileData.subscription_data.tier);

        if (availablePlans.length === 0) {
            return null;
        }

        // TODO: test to ensure abandoned cart request is recorded in time from client before redirect
        return (
            <>
                <div className="buy-right-now-button"
                     onClick={async () => {
                         validateIsLoggedIn().then(async (loggedIn) => {
                            let coupon = '';
                            if(contextualCouponCode !== null) {
                               coupon = `&subscription[coupon]=${contextualCouponCode}`;
                            }

                             if (loggedIn) {
                                 setTimeout(() => {
                                     setIsAuthModalOpen(false);
                                 }, 100);

                                 const email = await getUserEmail();
                                 handleAbandonedCart(email, selectedPlan.monthly.redirect);
                                 window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) +
                                     `&customer[email]=${encodeURIComponent(email)}` + coupon;
                             } else {
                                if(contextualEmail !== null) {
                                    setTimeout(() => {
                                        setIsAuthModalOpen(false);
                                    }, 100);
                                
                                    handleAbandonedCart(contextualEmail, selectedPlan.monthly.redirect);
                                    window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) +
                                        `&customer[email]=${encodeURIComponent(contextualEmail)}` + coupon;
                                } else {
                                    setIsAuthModalOpen(true);
                                }
                             }
                         });
                     }}>
                
                <AllCreditCards/>

                    {t('PayCard')}
                </div>
                <div className="buy-right-now-button-microtrans-crypto"
                     style={{marginTop: '15px', maxWidth: 'unset', width: '100%'}}
                     onClick={payWithCrypto}>
                    <CryptoIcon/>
                    {t('PayWithCrypto')}
                </div>
                <div className="get-pricing-final">
                    <header>{t('YouWillCharged')}</header>
                    {getFinalPrice()}
                </div>
            </>
        );
    };

    const getFinalPrice = () => {
        if (!selectedPlan) {
            return ""; // or return a default value
        }

        if (yearlyPricing) {
            const annualPricePerMonth = parseFloat(selectedPlan.annual.price.replace('$', ''));
            let totalAnnualPrice = (annualPricePerMonth * 12).toFixed(2);

            if (totalAnnualPrice.endsWith('.88')) {
                totalAnnualPrice = totalAnnualPrice.substring(0, totalAnnualPrice.length - 2) + '99';
            }

            return `${totalAnnualPrice} ${t('perYear')}`;
        } else {
            const monthlyPrice = parseFloat(selectedPlan.monthly.price.replace('$', ''));
            const monthlyPriceFormatted = monthlyPrice.toFixed(2);
            return `${monthlyPriceFormatted} ${t('PerMonth')}`;
        }
    };

    const handlePlanSelection = (planHeading) => {
        if (planHeading === t('freePlanHeading')) return;
        const plan = pricingPlans.find(p => p.heading === planHeading);
        if (plan) {
            // Store the previously selected plan
            const previousPlan = selectedPlan;

            setSelectedPlan(plan);
            setSelectedPlanHeading(planHeading);

            // Update the premiumPlans state
            setPremiumPlans([plan]);

            // Add the previously selected plan back to the extraAdditionalPricingData array
            if (previousPlan) {
                setExtraAdditionalPricingData(prevData => [...prevData, previousPlan]);
            }

            // Remove the newly selected plan from the extraAdditionalPricingData array
            setExtraAdditionalPricingData(prevData => prevData.filter(p => p.heading !== planHeading));
        }
        setShowMorePlans(false);
        document.body.style.overflow = 'unset';
    };

    useEffect(() => {
        if (profileData.subscription_data.tier === "PREMIUM" || profileData.subscription_data.tier === "GOLD") {
            handlePlanSelection(t('ultimatePlanHeading'));
        }
        //eslint-disable-next-line
    }, [profileData.subscription_data.tier]);

    const [isWide, setIsWide] = useState(window.innerWidth > 680);

    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [showMorePlans, setShowMorePlans] = useState(false);

    // Toggle body scroll based on the modal visibility
    useEffect(() => {
        if (showMorePlans) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        // Cleanup function to ensure scrolling is re-enabled
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showMorePlans]);

    // Close modal if clicked outside of it
    const handleClickOutside = (event) => {
        if (event.target.classList.contains('backdrop')) {
            setShowMorePlans(false)
        }
    };

    useEffect(() => {
        // Check if the pricing page has been visited before
        const visitedPricing = localStorage.getItem('visitedPricing');

        if (!visitedPricing) {
            // If not, set visitedPricing to true and reset the timer
            localStorage.setItem('visitedPricing', 'true');
            setTimerExpired(false);
        }
    }, [setTimerExpired]);

    const validateIsLoggedIn = async (popup = true) => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn && popup) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    const placeholderPlans = [
        {
            heading: `${t('premiumPlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('maximumMemory')}`,
                `${t('hundredAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,
                `${t('earlyAccessNewFeatures')}`,
                // `${t('dynamicScenario')}`,

            ]
        },
        {
            heading: `${t('ultimatePlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('ultimateMemory')}`,
                `${t('fiveHundredAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,
                `${t('earlyAccessNewFeatures')}`,
                // `${t('dynamicScenario')}`,

            ]
        },
        {
            heading: `${t('basicPlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('betterMemory')}`,
                `${t('fiftyAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,

            ]
        },
        {
            heading: `${t('freePlanHeading')}`,
            features: [
                `${t('unlimitedFreeModelAccess')}`,
                `${t('basicChatModel')}`,
                `${t('createYourOwnCharacters')}`,
                `${t('basicMemory')}`,
                `${t('nsfwContent')}`,
                `${t('premiumFeatures')}`,
                `${t('premiumRewards')}`,

            ]
        }
    ];

    const [showExitModal, setShowExitModal] = useState(false);

    const planHeadingMap = {
        GOLD: t('ultimatePlanHeading'),
        PREMIUM: t('premiumPlanHeading'),
        BASIC: t('basicPlanHeading'),
        FREE: t('freePlanHeading'),
    }

    const {registerHandler} = useExitIntent({

        cookie: {
            daysToExpire: 30,
            key: "use-exit-intent"
        },
        desktop: {
            triggerOnIdle: false,
            useBeforeUnload: false,
            triggerOnMouseLeave: true,
            delayInSecondsToTrigger: 1
        },
        mobile: {
            triggerOnIdle: true,
            delayInSecondsToTrigger: 5
        }
    });


    registerHandler({
        id: 'openModal',
        handler: () => {
            // Only show exit modal if user is on free plan and modal isn't already shown
            if (profileData.subscription_data.tier === 'FREE' && !showExitModal) {
                setShowExitModal(true);
            }
        }
    });

    // Additional handlers can be defined here if needed
    registerHandler({
        id: 'anotherHandler',
        // handler: () => console.log('Another handler'),
        context: ['onUnsubscribe', 'onMobile']
    });

    // const handleCloseExitModal = () => {
    //     setShowExitModal(false);
    // };

    const [showTrialOfferModal, setShowTrialOfferModal] = useState(false);
    const [contextualEmail, setContextualEmail] = useState(null);
    const [contextualCouponCode, setContextualCouponCode] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const retargetingParam = params.get('retargeting');
        const email = params.get('email');
        const couponCode = params.get('coupon');

        if (email) {
            setContextualEmail(email);
        }

        if (couponCode) {
            setContextualCouponCode(couponCode);
        }

        if (retargetingParam === 'campaign748293') {
            const timer = setTimeout(() => {
                setShowTrialOfferModal(true);
                params.delete('retargeting');
                params.delete('email');
                params.delete('coupon');
                window.history.replaceState({}, document.title, window.location.pathname);
            }, 500);
    
            return () => clearTimeout(timer); // Cleanup on unmount
        } else if (email) {
            params.delete('email');
            params.delete('coupon');
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []);

    const checkoutTrialOffer = async () => {
        const loggedIn = await validateIsLoggedIn(false);
        // TODO: Special follow up sequence trigger here for emails
        if (loggedIn) {
            const email = await getUserEmail();
            console.log(email);
            window.location.href = `https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium_trial-USD-Monthly&utm_source=cb-app-copy&customer[email]=${encodeURIComponent(email)}`;
        } else {
            if (contextualEmail !== null) {
                window.location.href = `https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium_trial-USD-Monthly&utm_source=cb-app-copy&customer[email]=${encodeURIComponent(contextualEmail)}`;
            } else {
                window.location.href = `https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium_trial-USD-Monthly&utm_source=cb-app-copy`;
            }
        }
    };

    const TrialOfferModal = ({ onClose }) => {
        const { t } = useTranslation();
        const [isWide, setIsWide] = useState(window.innerWidth > 680);

        useEffect(() => {
            const handleResize = () => {
                setIsWide(window.innerWidth > 680);
            };

            window.addEventListener('resize', handleResize);

            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return (
            <div className="modal-backdrop" onClick={onClose}>
                <div className="updated-modal-content-notification" onClick={(e) => e.stopPropagation()}>
                    <div className="updated-notification-left">

                        <div className="updated-notification-label" style={{ textAlign: "left", fontSize: '1em', whiteSpace: 'nowrap' }}>
                            <h2>{t('TrialOffer')}</h2>
                            {!isWide && (
                                <div className="modal-close-icon-notification" onClick={onClose}>
                                    <CloseIcon />
                                </div>
                            )}
                        </div>

                        <div style={{
                            paddingLeft: window.innerWidth < 680 ? '20px' : '0',
                            paddingRight: window.innerWidth < 680 ? '20px' : '0'
                        }}
                            className="gap-between-notification">
                            <div className="sublabel-notification">
                                {t('TrialOfferDetails')}
                            </div>
                        </div>

                        <div className="features-grid">
                            <div className="feature-item-notification">
                                <MemoryIcon />
                                <span>{t('Feature1')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <InfinityIcon />
                                <span>{t('Feature2')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <ImageIcon />
                                <span>{t('Feature3')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <ElyzaIcon />
                                <span>{t('Feature4')}</span>
                            </div>
                        </div>

                        <div className="notification-buttons-container">
                            <button
                                onClick={() => { checkoutTrialOffer() }}
                                className="notification-button-main-updated"
                                style={{ marginTop: "0" }}
                            >
                                {t('StartTrial')}
                            </button>

                            <div style={{
                                display: "flex", width: '100%', justifyContent: 'center', marginTop: isWide ? '10px' : '0',

                            }}>

                                <span className="colored-purple-text">{t('NoThanks')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="updated-notification-right">
                        <div
                            className="updated-modal-close-icon-notification"
                            style={{ zIndex: 9999 }}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </div>
                        <div className="image-carousel-container-exit-intent">
                            {/* Replace with relevant image or remove if not needed */}
                            <img
                                src={UpsellElyzaDev}
                                alt="Trial Offer"
                                className="carousel-image-exit-intent"
                                draggable="false"
                                style={{
                                    opacity: 1,
                                    transition: 'opacity 0.5s ease-in-out',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    
    const ExitIntent = ({onClose}) => {
        const [currentImageIndex, setCurrentImageIndex] = useState(0);
        const [isTransitioning, setIsTransitioning] = useState(false);
        const images = [UpsellElyzaDev, UpsellRandomGirl1, UpsellRandomGirl2];
        const {t} = useTranslation();

        useEffect(() => {
            let interval;
            let transitionTimeout;

            const handleVisibilityChange = () => {
                if (document.hidden) {
                    clearInterval(interval);
                    clearTimeout(transitionTimeout);
                } else {
                    startInterval();
                }
            };

            const startInterval = () => {
                if (interval) clearInterval(interval);
                if (transitionTimeout) clearTimeout(transitionTimeout);

                interval = setInterval(() => {
                    if (!document.hidden) {
                        setIsTransitioning(true);

                        // Use a separate state update to ensure proper ordering
                        transitionTimeout = setTimeout(() => {
                            setCurrentImageIndex(prev => (prev + 1) % images.length);
                            setIsTransitioning(false);
                        }, 500); // Increased from 300ms to 500ms for smoother transition
                    }
                }, 8000);
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);
            startInterval();

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
                clearInterval(interval);
                clearTimeout(transitionTimeout);
            };
        }, [images.length]);

        const processQuickPayment = async () => {
            const loggedIn = await validateIsLoggedIn();

            let coupon = '';
            if(contextualCouponCode !== null) {
                coupon = `&subscription[coupon]=${contextualCouponCode}`;
            }

            if (loggedIn) {
                setTimeout(() => {
                    setIsAuthModalOpen(false);
                }, 100);
        

                const email = await getUserEmail();
                handleAbandonedCart(email, 'elyza_basic-USD-Monthly');
                window.location.href = (`https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Monthly&utm_source=cb-app-copy&customer[email]=${encodeURIComponent(email)}` + coupon);
            } else {
                if(contextualEmail !== null) {
                    setTimeout(() => {
                        setIsAuthModalOpen(false);
                    }, 100);
                    
                    handleAbandonedCart(contextualEmail, 'elyza_basic-USD-Monthly');
                    window.location.href = (`https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Monthly&utm_source=cb-app-copy&customer[email]=${encodeURIComponent(contextualEmail)}` + coupon);
                } else {
                    setIsAuthModalOpen(true);
                }

            }
        };

        return (
            <div className="modal-backdrop">
                <div className="updated-modal-content-notification">
                    <div className="updated-notification-left">

                        <div className="updated-notification-label"
                             style={{textAlign: "left", fontSize: '1em', whiteSpace: 'nowrap'}}>
                            <h2>{t('waitDontGo')}</h2>

                            {!isWide && (
                                <div className="modal-close-icon-notification" onClick={onClose}>
                                    <CloseIcon/>
                                </div>
                            )}
                        </div>

                        <div style={{
                            paddingLeft: window.innerWidth < 680 ? '20px' : '0',
                            paddingRight: window.innerWidth < 680 ? '20px' : '0'
                        }}
                             className="gap-between-notification">
                            <div className="sublabel-notification">
                                {t('getFullExperience')}
                            </div>
                        </div>


                        <div className="features-grid">
                            <div className="feature-item-notification">
                                <MemoryIcon/>
                                <span>{t('enhancedWord1')}<br/>{t('enhancedWord2')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <InfinityIcon/>
                                <span>{t('unlimitedWord1')}<br/>{t('unlimitedWord2')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <ImageIcon/>
                                <span>{t('premiumWord1')}<br/>{t('premiumWord2')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <ElyzaIcon/>
                                <span>{t('tokensWord1')}<br/>{t('tokensWord2')}</span>
                            </div>

                        </div>

                        <div className="notification-buttons-container">
                            <button
                                onClick={processQuickPayment}
                                className="notification-button-main-updated"
                                style={{marginTop: "0"}}
                            >
                                {t('unlockPremium')}
                            </button>

                            <div style={{
                                display: "flex",
                                width: '100%',
                                justifyContent: 'center',
                                marginTop: isWide ? '10px' : '0',

                            }}>

                                <span className="colored-purple-text">{t('priceAndCancel')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="updated-notification-right">
                        <div
                            className="updated-modal-close-icon-notification"
                            style={{zIndex: 9999}}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </div>
                        <div className="image-carousel-container-exit-intent">
                            <img
                                src={images[currentImageIndex]}
                                alt="Elyza Character"
                                className="carousel-image-exit-intent"
                                draggable="false"
                                style={{
                                    opacity: isTransitioning ? 0 : 1,
                                    transition: 'opacity 0.5s ease-in-out',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    async function payWithCrypto() {
        validateIsLoggedIn().then(async (loggedIn) => {
            if (loggedIn) {
                setTimeout(() => {
                    setIsAuthModalOpen(false);
                }, 100);

                window.location.href = (yearlyPricing ? selectedPlan.annual.redirectCrypto : selectedPlan.monthly.redirectCrypto) + `?email=${encodeURIComponent(await getUserEmail())}`
            } else {
                if(contextualEmail !== null) {
                    setTimeout(() => {
                        setIsAuthModalOpen(false);
                    }, 100);

                    window.location.href = (yearlyPricing ? selectedPlan.annual.redirectCrypto : selectedPlan.monthly.redirectCrypto) + `?email=${encodeURIComponent(contextualEmail)}`

                } else {
                    setIsAuthModalOpen(true);
                }
            }
        });
    }

    return (
        <>
            <HeaderMainMenu setSearchText={() => {
            }} searchText={''}/>

            {isAuthModalOpen &&
                (
                    <AuthModal
                        isOpen={isAuthModalOpen}
                        onClose={() => setIsAuthModalOpen(false)}
                    />
                )}

            {showExitModal && profileData.subscription_data.tier === 'FREE' && !isAuthModalOpen && (
                <ExitIntent isOpen={showExitModal} onClose={() => setShowExitModal(false)}/>
            )}

            {showTrialOfferModal && (
                <TrialOfferModal isOpen={showTrialOfferModal} onClose={() => setShowTrialOfferModal(false)} />
            )}

            {!timerExpired ? (<div className="top-timer-container-main-pricing"
                                   style={{display: timerExpired !== false ? "none" : 'block'}}
            >
                <TopTimer/>
            </div>) : null}

            <div className="new-pricing-page-container">
                {isWide && (
                    <div className="top-pricing-row-container"
                         style={{paddingTop: timerExpired !== false ? "60px" : '100px'}}
                    >

                        <div className="new-toggle-pricing">

                            <div className="new-pricing-subtitle">
                                <h2>{t('UpgradePlanPricing')}</h2>
                                <span>{t('PremiumPlan')}</span>
                                <span className="highlight-text-price">{t('PremiumDiscount')}</span>
                                <span>{t('NextHour')}</span>
                                <span>
                                <br/>
                                    {t('Anonym')}
                                </span>
                            </div>

                            {pricingOptions.map((option) => (
                                <div
                                    key={option.key}
                                    className={`new-pricing-btn ${option.isActive ? "new-pricing-btn-active" : ""}`}
                                    onClick={() => setYearlyPricing(option.key === "annual")}
                                >
                                    <div className="discount-amount-pricing">
                                        {option.discountLabel}
                                    </div>

                                    {option.mostPopular && (
                                        <div className="most-popular-pin">
                                            <FireIcon /> {t('POPULAR')}
                                        </div>
                                    )}

                                    <div className="pricing-title-text">{option.title}</div>

                                    <div className="pricing-container-general">
                                        <div className="pricing-cost-text-price">
                                            {option.price}
                                        </div>
                                        <div className="pricing-cost-text-main">
                                            <div className="pricing-cost-text">
                                                <span className="crossed-out">{option.originalPrice}</span>
                                                <span>{t('PerMonth')}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pricing-subtitle-text">{t('CancelAnytime')}</div>
                                </div>
                            ))}
                        </div>

                        <div className="pricing-container-top-column">

                            {getAvailablePlans(profileData.subscription_data.tier).length > 0 && (
                                <>
                                    {renderPremiumPlans()}
                                    {renderPricingOptions()}
                                </>
                            )}

                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}

                {!isWide && (
                    <div className="top-pricing-row-container"
                         style={{paddingTop: timerExpired !== false ? "60px" : '100px'}}
                    >
                        <div className="new-toggle-pricing">
                            <div className="left-padding-text-container">
                                <h2>{t("UpgradePlanPricing")}</h2>

                                <div className="new-pricing-subtitle">
                                    <span>{t('PremiumPlan')}</span>
                                    <span className="highlight-text-price">{t('PremiumDiscount')}</span>
                                    <span>{t('NextHour')}</span>
                                    <span><br></br>{t('Anonym')}</span>
                                </div>

                            </div>

                            <div className="card-container-main-premium">
                                {renderPremiumPlans()}

                                <div className="pricing-swiper-component">
                                    <PricingToggleSlider
                                        yearlyPricing={yearlyPricing}
                                        setYearlyPricing={setYearlyPricing}
                                        selectedPlan={selectedPlan}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="pricing-container-top-column">

                            <div className="buy-right-now-button"
                                 onClick={async () => {
                                     validateIsLoggedIn().then(async (loggedIn) => {
                                        let coupon = '';
                                        if(contextualCouponCode !== null) {
                                           coupon = `&subscription[coupon]=${contextualCouponCode}`;
                                        }

                                         if (loggedIn) {
                                             setTimeout(() => {
                                                 setIsAuthModalOpen(false);
                                             }, 100); // Add delay here

                                             const email = await getUserEmail();
                                             handleAbandonedCart(email, selectedPlan.monthly.redirect);
                                             window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) + `&customer[email]=${encodeURIComponent(email)}` + coupon;
                                         } else {
                                             setIsAuthModalOpen(true);
                                         }
                                     });
                                 }}>
                                    <AllCreditCards/>
                                {t('PayCard')}
                            </div>

                            <div
                                className="buy-right-now-button-crypto"
                                onClick={payWithCrypto}>
                                <CryptoIcon/>
                                {t('PayWithCrypto')}
                            </div>
                            <div className="get-pricing-final">
                                <header>{t('YouWillCharged')}</header>
                                {getFinalPrice()}
                            </div>
                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}

                <div className="bottom-of-the-page-container">

                    <div className="left-padding-text-container">
                        <h2>{t('ThisCurrentPlan')}</h2>

                        {profileData.subscription_data.tier !== 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                <span>
                                    {t('ItsNeat1')}
                                    <br/>
                                    {t('ItsNeat2')}
                                    {' '}
                                    <span className="highlight-text-price">{t('onBunchStuff')}</span>
                                </span>

                                    {profileData.subscription_data.tier !== 'PREMIUM' && !showMorePlans && (
                                        <div
                                            className="buy-right-now-button-credit-addons"
                                            onClick={() => setShowMorePlans(true)}
                                        >
                                            {t('ViewOtherPlan')}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {profileData.subscription_data.tier === 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                <span>{t('MaxxedOut')} <br/>{t('ThanksForUsing')}
                                    <span className="highlight-text-price">Elyza!
                                    </span></span>
                                </div>
                            </>
                        )}

                        <div className='buy-right-now-button-credit-addons'
                             onClick={() => navigate('/addons')}
                        >
                            {t('GetMoreTokens')}
                        </div>

                        {profileData.subscription_data.tier === 'PREMIUM' && (
                            <div className='buy-right-now-button-credit-addons'
                                 onClick={() => setShowMorePlans(true)}>
                                {t('ViewOtherPlans')}
                            </div>
                        )}

                    </div>

                    {planHeadingMap[profileData.subscription_data.tier] && (
                        <>
                            {placeholderPlans
                                .filter(
                                    (plan) =>
                                        plan.heading === planHeadingMap[profileData.subscription_data.tier]
                                )
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    <img src={AnimeGirl} alt="Anime Girl" className="anime-girl" draggable="false"/>
                </div>

                <div className="faq-main-component">
                    <PricingFrequentQuestions/>
                </div>

            </div>

            <MorePlansModal
                showMorePlans={showMorePlans}
                handleClickOutside={handleClickOutside}
                extraAdditionalPricingData={extraAdditionalPricingData}
                selectedPlanHeading={selectedPlanHeading}
                handlePlanSelection={handlePlanSelection}
                isWide={isWide}
            />

            <MobileNavigation/>

        </>
    );
};

export default NewPricingPage;