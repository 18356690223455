import React, {useEffect, useRef, useState} from 'react';
import './ProfileControlMenu.css';
import ReactDOM from 'react-dom';
import {ReactComponent as ArrowRight} from './icons/arrow.svg';
import {ReactComponent as ElyzaCoin} from '../../RewardScreen/RewardIcons/elyzaCoin.svg';
import {ReactComponent as ProfileIcon} from './icons/profileIcon.svg';
import {ReactComponent as PresentIcon} from '../../MainMenuPage/icons/presentIcon.svg';
import {ReactComponent as CanRedeemIcon} from '../../MainMenuPage/icons/canRedeemIcon.svg';
import {ReactComponent as ImageIcon} from './icons/generateImage.svg';
import {ReactComponent as SignOutIcon} from './icons/signOutIcon.svg';
import {ReactComponent as SupportIcon} from './icons/supportIcon.svg';
import {ReactComponent as HiddenGemIcon} from './icons/TrulyHiddenGem.svg';

import {Link, useNavigate} from 'react-router-dom';
import {supabase} from "../../../App";
import {useProfileData} from "../../Context/ProfileDataContext";
import {useTranslation} from "react-i18next";
import {useUserSettings} from "../UserSettingContext";

const ProfileControlMenu = ({isOpen, onClose}) => {

    const {profileData, userTokens} = useProfileData();

    const menuRef = useRef(null);
    const [isAnimatingOut, setIsAnimatingOut] = useState(false);
    const {toggleProfileEditModal, toggleMainRewardModal, toggleSupportModal} = useUserSettings();

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        setIsAnimatingOut(true);
        setTimeout(() => {
            setIsAnimatingOut(false);
            onClose();
        }, 300); // This should match the CSS transition duration
    };

    const handleUsernameClick = (tagName) => {
        handleClose();
        navigate(`/user/${tagName}`);
    }

    const handleSignOut = async () => {
        const {error} = await supabase.auth.signOut();
        if (error) {
            console.error('Error signing out:', error.message);
        } else {

            localStorage.clear();

            navigate('/'); // Replace with your routing logic
            window.location.reload();
        }
    };

    const isPremiumShiny = profileData.subscription_data.tier === 'FREE'

    const [hasRewards, setHasRewards] = useState(false);

    useEffect(() => {
        if (profileData.has_rewards) {
            setHasRewards(true);
        } else {
            setHasRewards(false);
        }
    }, [profileData.has_rewards]);

    const profileOptions = [
        {
            title: t('YourProfile'),
            icon: <ProfileIcon/>,
            action: () => {
                toggleProfileEditModal();
                handleClose();
            }
        },
        {
            title: t('Rewards'),
            icon: (
                <div className="relative">
                <PresentIcon/>
                {hasRewards && (
                    <div style={{
                        position: 'absolute',
                        top: '193px',
                        left: '32px',
                        width: '16px',
                        height: '16px'
                    }}>
                        <CanRedeemIcon/>
                    </div>
                )}
            </div>
            ),
            action: () => {
                toggleMainRewardModal();
                handleClose();
            }
        },
        {
            title: t('GenerateImage'),
            icon: <ImageIcon/>,
            action: () => {
                handleClose();
                navigate('/generate');
            }
        },
        {
            title: t('upgrade'),
            icon: <HiddenGemIcon/>,
            action: () => {
                handleClose();
                navigate('/pricing');
            },
            isPremium: isPremiumShiny
        },
        {
            title: t('Support'),
            icon: <SupportIcon/>,
            action: () => {
                toggleSupportModal();
                handleClose();
            }
        },
        {
            title: t('SignOut'),
            icon: <SignOutIcon/>,
            action: handleSignOut
        },
    ];

    if (!isOpen && !isAnimatingOut) return null;

    return ReactDOM.createPortal(
        (<div className={`modal-backdrop-profile-control ${isOpen && !isAnimatingOut ? 'fade-in' : 'fade-out'}`}>
                <div
                    className={`modal-content-profile-control-menu ${isOpen && !isAnimatingOut ? 'pop-in' : 'pop-out'}`}
                    ref={menuRef}
                >
                    <div className="control-menu-header"
                         onClick={() => handleUsernameClick(profileData.tagname)}
                    >
                        <div className="user-avatar-profile-control-menu">
                            <img
                                src={profileData.profile}
                                alt=''
                                className='user-avatar-profile-control-menu'
                            />
                        </div>
                        <div className="profile-control-menu-username">
                            @{profileData.tagname}
                        </div>

                        <div className="profile-control-menu-arrow">
                            <ArrowRight/>
                        </div>
                    </div>

                    <div className="line-container">
                        <span className="text-line-container">{t('Balance')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="balance-counter-main">
                        <div className="profile-count-counter">
                            {userTokens}
                            <ElyzaCoin/>
                        </div>
                        <Link
                            to="/addons"
                            style={{textDecoration: 'none'}}
                            className="get-more-tokens-sign"
                        >
                            {t('GetMoreTokens')}
                        </Link>
                    </div>

                    <div className="line-container">
                        <span className="text-line-container">{t('Options')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="profile-control-options">
                        {profileOptions.map((option, index) => (
                            <div
                                className="profile-control-option"
                                key={index}
                                onClick={option.action}
                            >
                                {/*{option.icon}*/}
                                {/*<span className="profile-control-option-title">{option.title}</span>*/}


                                <div className={option.isPremium ? "menu-icon-desktop-premium" : "menu-icon-desktop"}>
                                    {option.icon}
                                </div>
                                <span className={option.isPremium ? "menu-label-desktop-premium" : "menu-label-desktop"}>
                                    {option.title}
                                </span>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        document.getElementById('modal-root')
    );
};

export default ProfileControlMenu;