import {useState} from 'react';
import {getUserAccessToken, getUserID, supabase} from "../../App";

export const useMediaGeneration = () => {
    const [imageLoading, setImageLoading] = useState(false);
    const [audioLoading, setAudioLoading] = useState(false);

    //TODO: audio URL should not BE USED HERE

    const [subscriptionError, setSubscriptionError] = useState(false);

    const generateImage = async (messageId) => {
        setImageLoading(true);
        try {
            const id = await getUserID();
            const identityName = window.location.pathname.substring(6);

            const {data: character} = await supabase
                .from('characters')
                .select('id')
                .eq('user_id', id)
                .eq('identity_name', identityName)
                .single();

            if (!character) {
                throw new Error("Character not found");
            }

            const response = await fetch('https://api.elyza.app/v1/image-generation-fb', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    userId: id,
                    characterId: character.id,
                    messageId: messageId,
                })
            });

            const data = await response.json();

            // Check for token error first, before checking response.ok
            if (data.errorCode === 50) {
                setSubscriptionError(true);
                return {
                    success: false,
                    error: "Not enough tokens",
                    blurredImage: data.blurred_image || null  // Include blurred image if available
                };
            }

            if (response.ok) {
                return {success: true, imageUrl: data.url};
            } else {
                return {success: false, error: data.error || "Failed to generate image"};
            }
        } catch (error) {
            console.error("Failed to generate image:", error);
            return {success: false, error: error.message};
        } finally {
            setImageLoading(false);
        }
    };

    const generateAudio = async (messageId) => {
        setAudioLoading(true)
        const identityName = window.location.pathname.substring(6);
        if (!identityName) {
            return {success: false, error: "Identity name not found"};
        }

        try {
            const id = await getUserID();
            const {data: character, error} = await supabase
                .from('characters')
                .select('id')
                .eq('user_id', id)
                .eq('identity_name', identityName)
                .single();

            if (error) {
                console.error(error);
                return {success: false, error: "Failed to fetch character data"};
            }

            if (messageId !== null && character?.id) {
                const response = await fetch('https://api.elyza.app/v1/new-generate-audio', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({
                        characterId: character.id,
                        messageId: messageId,
                    })
                });

                const data = await response.json();

                // Check for token error first, before checking response.ok
                if (data.errorCode === 50) {
                    setSubscriptionError(true);
                    return {success: false, error: "Not enough tokens"};
                }

                if (!response.ok) {
                    return {success: false, error: data.error || "Failed to generate audio"};
                }

                return {success: true, audioUrl: data.url, duration: data.duration};
            }
            return {success: false, error: "Invalid message or character ID"};
        } catch (error) {
            console.error("Failed to generate audio:", error);
            return {success: false, error: error.message};
        } finally {
            setAudioLoading(false);
        }
    };

    return {
        imageLoading,
        audioLoading,
        subscriptionError,
        setSubscriptionError,
        generateImage,
        generateAudio,
    };
};