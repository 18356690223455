import React, { useState } from 'react';
import './ResumePlanConfirmation.css';
import { ReactComponent as CloseIcon } from "../../components/MainMenuPage/icons/cross.svg";
import { ReactComponent as CheckMark } from "./files/checkmark.svg";

const ResumePlanConfirmation = ({ isOpen, onClose, onConfirmResume }) => {
    const [isResuming, setIsResuming] = useState(false);
    const [resumeSuccess, setResumeSuccess] = useState(false);

    const handleResumeConfirm = async () => {
        setIsResuming(true);
        await onConfirmResume();
        setIsResuming(false);
        setResumeSuccess(true);
        
        // Auto close after success message is shown
        setTimeout(() => {
            onClose();
        }, 3000);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" style={{ zIndex: 100005 }}>
            <div className="modal-content-notification resume-plan-modal">
                <div className='notification-label'>
                    Resume Subscription
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='resume-plan-content'>
                    {resumeSuccess ? (
                        <div className="resume-success-message">
                            <CheckMark />
                            <span>Your subscription has been resumed successfully!</span>
                        </div>
                    ) : (
                        <>
                            <div className="resume-plan-description">
                                Are you sure you want to resume your subscription? 
                                Your billing cycle will restart immediately and you'll regain access to all premium features.
                            </div>
                            <div className="resume-plan-actions">
                                <button 
                                    onClick={onClose} 
                                    className="notification-button-main"
                                    style={{ backgroundColor: '#656565', maxWidth: '50%', marginTop: '0' }}
                                >
                                    Cancel
                                </button>
                                <button 
                                    onClick={handleResumeConfirm} 
                                    className="notification-button-main"
                                    disabled={isResuming}
                                    style={{ maxWidth: '50%', marginTop: '0' }}
                                >
                                    {isResuming ? 'Resuming...' : 'Resume Subscription'}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResumePlanConfirmation;
