import React, { useEffect, useState } from 'react';
import '../../components/Notifications/ModalAndNotifications.css';
import './SubscriptionModal.css';
import {ReactComponent as CloseIcon} from "../../components/MainMenuPage/icons/cross.svg";
import {ReactComponent as CheckMark} from "./files/checkmark.svg";
import {getUserAccessToken} from "../../App";
import CancellationModal from '../../pages/SubscriptionHandling/CancellationModal';
import FinalModal from '../../pages/SubscriptionHandling/FinalModal';
import PauseSubscriptionModal from './PauseSubscriptionScreen';
import ResumePlanConfirmation from './ResumePlanConfirmation';
import RenewalConfirmation from './RenewalConfirmation';

const SubscriptionPlanModal = ({isOpen, onClose, subscriptionData}) => {
    // const {t} = useTranslation();
    const [planDuration, setPlanDuration] = useState('');
    const [plan, setPlan] = useState('');
    const [areYouSureModal, setAreYouSureModal] = useState(false);
    const [finalMessageOpen, setFinalMessageOpen] = useState(false);
    const [subscriptionID, setSubscriptionID] = useState('');
    const [pauseSubscriptionModal, setPauseSubscriptionModal] = useState(false);
    const [resumePlanModal, setResumePlanModal] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isNonRenewing, setIsNonRenewing] = useState(false);
    const [autoResumeDate, setAutoResumeDate] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [showRenewalConfirmation, setShowRenewalConfirmation] = useState(false);

    // Initialize state from subscriptionData
    useEffect(() => {
        if (subscriptionData) {
            setSubscriptionID(subscriptionData.id);
            setIsPaused(subscriptionData.status === 'paused' || subscriptionData.supabaseStatus === 'PAUSED');
            setIsNonRenewing(subscriptionData.status === 'non_renewing');
            
            if (subscriptionData.pausedUntil) {
                setAutoResumeDate(subscriptionData.pausedUntil);
            }
            
            if (subscriptionData.currentTermEnd) {
                setExpirationDate(subscriptionData.currentTermEnd);
            }
            
            // Set plan information based on planName
            if (subscriptionData.planName) {
                // Extract plan type (basic, premium, gold)
                const planType = subscriptionData.planName.split('-')[0].replace('elyza_', '').toUpperCase();
                setPlan(planType);
                
                // Extract plan duration (Monthly, Yearly)
                const duration = subscriptionData.planName.includes('-Yearly') ? 'Yearly' : 'Monthly';
                setPlanDuration(duration);
            }
        }
    }, [subscriptionData]);

    const pauseSubscription = async (resumeDate) => {
        try {
            const result = await fetch('https://api.elyza.app/v1/handle-cancellation-funnel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    operation: 'pauseSubscription',
                    subscriptionId: subscriptionID,
                    resumeDate: resumeDate
                })
            });
            const data = await result.json();
            
            // Toggle isPaused state after successful API call
            if (data.success) {
                setIsPaused(true);
                onClose(); // Close the modal after successful operation
            }
            return data;
        } catch (error) {
            console.error("Error pausing subscription:", error);
            return { success: false, error: error.message };
        }
    }

    const resumeSubscription = async () => {
        try {
            const result = await fetch('https://api.elyza.app/v1/handle-cancellation-funnel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    operation: 'resumeSubscription',
                    subscriptionId: subscriptionID
                })
            });
            const data = await result.json();
            
            // Toggle isPaused state after successful API call
            if (data.success) {
                setIsPaused(false);
                setResumePlanModal(false);
                onClose(); // Close the modal after successful operation
            }
            return data;
        } catch (error) {
            console.error("Error resuming subscription:", error);
            return { success: false, error: error.message };
        }
    }

    const removeScheduledCancellation = async () => {
        try {
            const result = await fetch('https://api.elyza.app/v1/handle-cancellation-funnel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    operation: 'removeScheduledCancellation',
                    subscriptionId: subscriptionID
                })
            });
            const data = await result.json();
            
            if (data.success) {
                setIsNonRenewing(false);
                // Show renewal confirmation instead of closing
                setShowRenewalConfirmation(true);
            }
            return data;
        } catch (error) {
            console.error("Error removing scheduled cancellation:", error);
            return { success: false, error: error.message };
        }
    }

    // Format the date to a user-friendly format (Month Day, Year)
    const formatDate = (dateString) => {
        if (!dateString) return '';
        
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    // Handle closing all modals
    const handleCloseAll = () => {
        setShowRenewalConfirmation(false);
        onClose();
    };

    if (!isOpen) return null;

    // Determine subscription display name
    const subscriptionDisplayName = plan === 'GOLD' ? 'ULTIMATE' : plan;

    return (
        <>
        {pauseSubscriptionModal && (
            <PauseSubscriptionModal
                isOpen={pauseSubscriptionModal}
                onClose={() => setPauseSubscriptionModal(false)}
                pauseSubscription={pauseSubscription}
            />
        )}
        
        {finalMessageOpen && (
            <FinalModal
                isOpen={finalMessageOpen}
                onClose={() => setFinalMessageOpen(false)}
                subscriptionID={subscriptionID}
            />
        )}

        {areYouSureModal && (
            <CancellationModal
                isOpen={areYouSureModal}
                onClose={() => setAreYouSureModal(false)}
                continueCancellation={() => setFinalMessageOpen(true)}
            />
        )}

        {resumePlanModal && (
            <ResumePlanConfirmation
                isOpen={resumePlanModal}
                onClose={() => setResumePlanModal(false)}
                onConfirmResume={resumeSubscription}
            />
        )}

        {showRenewalConfirmation && (
            <RenewalConfirmation
                isOpen={showRenewalConfirmation}
                onClose={handleCloseAll}
            />
        )}

        <div className="modal-backdrop"
            style={{zIndex: 10000}}
        >
            <div className="modal-content-notification">
                <div className='notification-label'>
                    My subscription plan
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    View and make changes to your subscription or
                    billing details.
                </div>

                <div className="subscription-plan">
                    <div className='modal-plan'>
                        <div className="modal-plan-desc">
                            <span className="modal-desc-text">{planDuration}</span>
                            <span className="modal-desc-text">
                                Elyza {subscriptionDisplayName}
                                {isPaused && <span style={{marginLeft: '5px', fontSize: '0.8em', color: '#ffcc00'}}>(Paused)</span>}
                                {isNonRenewing && <span style={{marginLeft: '5px', fontSize: '0.8em', color: '#ff6b6b'}}>(Non-renewing)</span>}
                            </span>
                        </div>
                        <div className='features-list'>
                            {/* Features based on plan */}
                            {plan === 'FREE' && (
                                <>
                                    <span>Unlimited Basic Messages</span>
                                    <span>Create Free Characters</span>
                                    <span>Free Rewards</span>
                                </>
                            )}
                            {plan === 'BASIC' && (
                                <>
                                    <span className='feature-child'><CheckMark/>Unlimited Messages</span>
                                    <span className='feature-child'><CheckMark/>Better Memory</span>
                                    <span className='feature-child'><CheckMark/>60 Tokens / month</span>
                                    <span className='feature-child'><CheckMark/>Basic Rewards</span>
                                </>
                            )}
                            {plan === 'PREMIUM' && (
                                <>
                                    <span className='feature-child'><CheckMark/>Unlimited Messages</span>
                                    <span className='feature-child'><CheckMark/>Improved Memory</span>
                                    <span className='feature-child'><CheckMark/>125 Tokens / month</span>
                                    <span className='feature-child'><CheckMark/>Premium Rewards</span>
                                    <span className='feature-child'><CheckMark/>Contextual Memory</span>
                                </>
                            )}
                            {plan === 'GOLD' && (
                                <>
                                    <span className='feature-child'><CheckMark/>Unlimited Messages</span>
                                    <span className='feature-child'><CheckMark/>Maximum Memory</span>
                                    <span className='feature-child'><CheckMark/>600 Tokens / month</span>
                                    <span className='feature-child'><CheckMark/>Ultimate Rewards</span>
                                    <span className='feature-child'><CheckMark/>Contextual Memory</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='current-plan'>Current Plan</div>
                </div>

                <span
                    style={{display: 'flex', marginTop: '15px', marginBottom: '15px', gap: '2px'}}
                >
                    <span>
                        Need more?
                    </span>
                    <a href="/pricing" style={{textDecoration: 'none', fontStyle: 'italic', color: '#fff', cursor: 'pointer'}}>View other plans</a>
                </span>
                
                {isPaused && autoResumeDate && (
                    <div 
                        style={{
                            backgroundColor: 'rgba(151, 109, 253, 0.2)', 
                            padding: '12px', 
                            borderRadius: '8px', 
                            marginBottom: '15px',
                            textAlign: 'center',
                            fontSize: '0.95em'
                        }}
                    >
                        Your plan is currently paused and will automatically resume on <span style={{fontWeight: 'bold'}}>{formatDate(autoResumeDate)}</span>.
                    </div>
                )}
                
                {isNonRenewing && expirationDate && (
                    <div 
                        style={{
                            backgroundColor: 'rgba(255, 107, 107, 0.2)', 
                            padding: '12px', 
                            borderRadius: '8px', 
                            marginBottom: '15px',
                            textAlign: 'center',
                            fontSize: '0.95em'
                        }}
                    >
                        Your subscription is set to cancel on <span style={{fontWeight: 'bold'}}>{formatDate(expirationDate)}</span>. 
                        You will still have access to all features until this date.
                    </div>
                )}
                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px'}}>
                    {isNonRenewing ? (
                        <button 
                            onClick={removeScheduledCancellation} 
                            className="notification-button-main"
                            style={{margin: 'unset'}}
                        >
                            Renew Subscription
                        </button>
                    ) : (
                        <button 
                            onClick={() => isPaused ? setResumePlanModal(true) : setPauseSubscriptionModal(true)} 
                            className="notification-button-main"
                            style={{margin: 'unset'}}
                        >
                            {isPaused ? 'Resume Plan' : 'Pause Plan'}
                        </button>
                    )}
                    
                    <button 
                        onClick={() => {
                            if (isPaused) {
                                onClose();
                            } else if (isNonRenewing) {
                                onClose();
                            } else {
                                setAreYouSureModal(true);
                            }
                        }}
                        style={{width: '50%', backgroundColor: '#656565', margin: 'unset'}}
                        className="notification-button-main"
                    >
                        {isPaused || isNonRenewing ? 'Close' : 'Cancel Plan'}
                    </button>
                </div>

            </div>
        </div>
        </>
    );
};

export default SubscriptionPlanModal;