import React, { useState, useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../../../icons/cross.svg';
import { ReactComponent as CirclePlusIcon } from "./CustomizationIcons/CirclePlusIcon.svg";
import { ReactComponent as ApplyIcon } from "./CustomizationIcons/ApplyIcon.svg";
import { ReactComponent as EditIcon } from "./CustomizationIcons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "./CustomizationIcons/DeleteIcon.svg";
import './CustomizationModal.css';
import { getUserID } from "../../../App";
import {
    editMemoryFragment,
    editPersonality,
    getUpdatedMemoriesAndPersonalities,
    deletePersonality,
    deleteMemoryFragment,
    selectMemoryFragments,
    selectPersonality
} from "./CustomizationFunctions";
import AddCustomization from "./AddCustomization";
import DeleteConfirmationModal from "./ConfirmDeletePortal";
import EditCustomizationModal from "./EditCustomizationModal";
import './MobileCustomizationModal.css';
import {useTranslation} from "react-i18next";
import UniversalFilterModal from "../../Modals/UniversalFilterModal";

const PopupMenu = ({ isOpen, onApply, onEdit, onDelete, itemName }) => {

    const {t} = useTranslation();

    if (!isOpen) return null;

    return (

        <div className="popup-overlay">
            <div className="popup-content">
                <button onClick={onApply} className="popup-button apply-button">
                    <ApplyIcon />
                    {t('Apply')}
                </button>
                <button onClick={onEdit} className="popup-button edit-button">
                    <EditIcon />
                    {t('Edit')}
                </button>
                <button onClick={onDelete} className="popup-button delete-button">
                    <DeleteIcon />
                    {t('Delete')}
                </button>
            </div>
        </div>
    );
};

const ItemGrid = ({ items, type, onItemApply, onItemEdit, onItemDelete, onAddItem }) => {
    const [openMenuId, setOpenMenuId] = useState(null);

    const handleItemClick = (id) => {
        setOpenMenuId(prevId => prevId === id ? null : id);
    };

    return (
        <div className="user-profile-grid">
            {items.map((item) => (
                <div
                    key={item.id}
                    className={`profile-box ${item.selected ? 'selected' : ''}`}
                    onClick={() => handleItemClick(item.id)}
                >
                    <div className='profile-head-header'>
                        <h2>{item.name}</h2>
                    </div>
                    <span className='profile-box-bottom-text'>{item.description}</span>
                    <PopupMenu
                        isOpen={openMenuId === item.id}
                        onClose={() => setOpenMenuId(null)}
                        onApply={(event) => {
                            event.stopPropagation();
                            onItemApply(item.id);
                            setOpenMenuId(null);
                        }}
                        onEdit={(event) => {
                            event.stopPropagation();
                            onItemEdit(item.id);
                            setOpenMenuId(null);
                        }}
                        onDelete={(event) => {
                            event.stopPropagation();
                            onItemDelete(item.id);
                            setOpenMenuId(null);
                        }}
                        itemName={item.name}
                    />
                </div>
            ))}
            {items.length < 6 && (
                <div className="profile-box new-profile-box" onClick={onAddItem}>
                    <div className="new-profile-content">
                        <span className="plus-icon">
                            <CirclePlusIcon />
                        </span>
                        <p>Add {type === 'personalities' ? 'Profile' : 'Memory'} Card</p>
                    </div>
                </div>
            )}
        </div>
    );
};

const MobileCustomizationModal = ({ isOpen, onCancel }) => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addingItemType, setAddingItemType] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [memoriesAndPersonalities, setMemoriesAndPersonalities] = useState({
        memories: [],
        personalities: []
    });
    const [isLoading, setIsLoading] = useState(true);

    const handleItemApply = (id, type) => {
        setMemoriesAndPersonalities(prev => ({
            ...prev,
            [type]: prev[type].map(item => ({
                ...item,
                selected: item.id === id ? !item.selected : type === 'personalities' ? false : item.selected
            }))
        }));
        setHasChanges(true);
    };

    const fetchMemoriesAndPersonalities = async (identityName) => {
        setIsLoading(true);
        try {
            const updatedMemoriesAndPersonalities = await getUpdatedMemoriesAndPersonalities(identityName);
            setMemoriesAndPersonalities(updatedMemoriesAndPersonalities);
        } catch (error) {
            console.error("Error fetching memories and personalities:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;
        
        const fetchData = async () => {
            if (!isOpen) return;
            
            try {
                const identityName = window.location.pathname.substring(6);
                
                if (isMounted) {
                    await fetchMemoriesAndPersonalities(identityName);
                }
            } catch (error) {
                console.error("Error in fetchData:", error);
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };
        
        fetchData();
        
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, [isOpen]);

    const [showWarningModal, setShowWarningModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleItemEdit = async (id, updates) => {
        const type = id.startsWith('pers') ? 'personalities' : 'memories';
        const existingItem = memoriesAndPersonalities[type].find(item => item.id === id);
        const updatedItem = {
            ...existingItem,
            name: updates.name,
            description: updates.description
        };
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);

        try {
            let result;
            if (type === 'personalities') {
                result = await editPersonality(userId, identityName, id, updatedItem.name, updatedItem.description);
            } else {
                result = await editMemoryFragment(userId, identityName, id, updatedItem.name, updatedItem.description);
            }

            if (result.error === 'Prohibited Content') {
                setShowWarningModal(true);
                setErrorMessage('Content violates our policies');
                return;
            }

            if (result.error) {
                console.error('Error updating item:', result.error);
                return;
            }

            setMemoriesAndPersonalities(prev => ({
                ...prev,
                [type]: prev[type].map(item => item.id === id ? updatedItem : item)
            }));

            setEditModalOpen(false);
            setCurrentItem(null);
        } catch (error) {
            console.error('Error updating item:', error);
        } finally {
            await fetchMemoriesAndPersonalities(identityName);
        }
    };

    const handleItemDelete = (id) => {
        setCurrentItem(id);
        setDeleteConfirmOpen(true);
    };

    const confirmDelete = async () => {
        const type = currentItem.startsWith('mem') ? 'memories' : 'personalities';
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);

        try {
            let result;
            if (type === 'memories') {
                result = await deleteMemoryFragment(userId, identityName, currentItem);
            } else {
                result = await deletePersonality(userId, currentItem);
            }

            if (result.error) {
                console.error(`Error deleting ${type}:`, result.error);
                return;
            }

            setMemoriesAndPersonalities(prev => ({
                ...prev,
                [type]: prev[type].filter(item => item.id !== currentItem)
            }));
        } catch (error) {
            console.error('Error deleting item:', error);
        } finally {
            setDeleteConfirmOpen(false);
            setCurrentItem(null);
            await fetchMemoriesAndPersonalities(identityName);
        }
    };

    const handleAddItem = (type) => {
        setAddingItemType(type);
        setAddModalOpen(true);
    };

    const handleSaveNewItem = async () => {
        const identityName = window.location.pathname.substring(6);
        try {
            setAddModalOpen(false);
            setAddingItemType(null);
        } catch (error) {
            console.error('Error adding item:', error);
        } finally {
            await fetchMemoriesAndPersonalities(identityName);
        }
    };

    const handleSave = async () => {
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);

        try {
            const selectedPersonality = memoriesAndPersonalities.personalities.find(p => p.selected);
            if (selectedPersonality) {
                await selectPersonality(userId, selectedPersonality.id);
            }

            const selectedMemoryIds = memoriesAndPersonalities.memories
                .filter(m => m.selected)
                .map(m => m.id);
            await selectMemoryFragments(userId, identityName, selectedMemoryIds);

            setHasChanges(false);
            onCancel();
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };

    const {t} = useTranslation();

    if (!isOpen) return null;


    return (
        <div className="main-space-finder-modal">
            <div className="app-modal-content-response-settings" onClick={(e) => e.stopPropagation()}>
                <div className="main-upper-text-row">
                    <div className="profile-setting-label">{t('CustomizationSettings')}</div>
                    <div className="close-modal-icon" onClick={onCancel}><CloseIcon /></div>
                </div>

                <div className="main-elyza-text-response-settings">
                    {t('SuperChargeExperience')}
                </div>

                {isLoading ? (
                    <div className="loading-container">
                        <p>{t('Loading customization data...')}</p>
                    </div>
                ) : (
                    <>
                        <div className="line-container">
                            <span className="text-line-container">{t('UserProfiles')}</span>
                            <div className="line"></div>
                        </div>

                        <div className="main-elyza-text-response-settings">
                            {t('CustomizationSettingsText1')}
                        </div>

                        <ItemGrid
                            items={memoriesAndPersonalities !== null ? memoriesAndPersonalities.personalities : []}
                            type="personalities"
                            onItemApply={(id) => handleItemApply(id, 'personalities')}
                            onItemEdit={(id) => {
                                setCurrentItem(memoriesAndPersonalities.personalities.find(item => item.id === id));
                                setEditModalOpen(true);
                            }}
                            onItemDelete={handleItemDelete}
                            onAddItem={() => handleAddItem('personalities')}
                        />

                        <div className="line-container">
                            <span className="text-line-container">{t('MemoryFragments')}</span>
                            <div className="line"></div>
                        </div>

                        <div className="main-elyza-text-response-settings">
                            {t('CustomizationSettingsText2')}
                        </div>

                        <ItemGrid
                            items={memoriesAndPersonalities !== null ? memoriesAndPersonalities.memories : []}
                            type="memories"
                            onItemApply={(id) => handleItemApply(id, 'memories')}
                            onItemEdit={(id) => {
                                setCurrentItem(memoriesAndPersonalities.memories.find(item => item.id === id));
                                setEditModalOpen(true);
                            }}
                            onItemDelete={handleItemDelete}
                            onAddItem={() => handleAddItem('memories')}
                        />

                        <div className="a-straight-line-3"></div>

                        <div className="footer-button-container">
                            <button className="need-help-button" style={{backgroundColor: '#757575'}} onClick={onCancel}>
                                {t('Cancel')}
                            </button>
                            <button className="save-settings-button" onClick={handleSave} disabled={!hasChanges}>
                                {t('save')}
                            </button>
                        </div>
                    </>
                )}
            </div>

            {editModalOpen && currentItem && (
                <EditCustomizationModal
                    isOpen={editModalOpen}
                    onClose={() => {
                        setEditModalOpen(false);
                        setCurrentItem(null);
                    }}
                    item={currentItem}
                    onSave={(updatedItem) => handleItemEdit(currentItem.id, updatedItem)}
                    type={currentItem.id.startsWith('pers') ? 'personality' : 'memory'}
                />
            )}

            {deleteConfirmOpen && (
                <DeleteConfirmationModal
                    isOpen={deleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                    onConfirm={confirmDelete}
                />
            )}

            {addModalOpen && (
                <AddCustomization
                    isOpen={addModalOpen}
                    onClose={() => {
                        setAddModalOpen(false);
                        setAddingItemType(null);
                    }}
                    onSaveNew={handleSaveNewItem}
                    type={addingItemType}
                />
            )}
            <UniversalFilterModal
                isOpen={showWarningModal}
                onClose={() => setShowWarningModal(false)}
                message={errorMessage}
            />
        </div>
    );
};

export default MobileCustomizationModal;