import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';
import {potentialLoraSearch} from "./AdvancedCreationFunctions";
import debounce from 'lodash/debounce';
import i18n from "i18next";

const AdvancedCharacterCreationContext = createContext();

export const useAdvancedCharacterCreation = () => useContext(AdvancedCharacterCreationContext);

export const AdvancedCharacterCreationProvider = ({children}) => {

    const [currentLanguage, setCurrentLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        return (savedLanguage || i18n.language) ?? 'EN';
    });

    let upperCaseLanguage = currentLanguage.toUpperCase();

    const [advancedCharData, setAdvancedCharData] = useState(() => {
        const savedData = localStorage.getItem('advancedCharacterCreationData');
        return savedData ? JSON.parse(savedData) : {
            style: '',
            gender: 'Female',
            isPublic: 'Private',
            nsfw: 'NSFW',
            charName: '',
            description: '',
            appearance: '',
            personality: '',
            scenario: '',
            dialogue: '',
            age: '',
            specialCase: '',
            loraAirUrl: '',
            loraTrainedWords: '',
            profileURL: '',
            languageSelection: upperCaseLanguage ?? 'EN',
            voiceSelection: '',

            clonedVoiceName: 'Custom Voice',
            clonedVoiceURL: ''
        };
    });

    const [loras, setLoras] = useState([]);

    const [debouncedCharName, setDebouncedCharName] = useState(advancedCharData.charName || '');

    const [useAiGeneration, setUseAiGeneration] = useState(false);

    const [imageFile, setImageFile] = useState(advancedCharData.profileURL || '');

    const [showPrompt, setShowPrompt] = useState(false);

    const [prompt, setPrompt] = useState('');

    const [generatedProfileImage, setGeneratedProfileImage] = useState('');

    const [selectedVoice, setVoiceSelection] = useState(advancedCharData.voiceSelection || '');

    //eslint-disable-next-line
    const debouncedSetCharName = useCallback(
        debounce((value) => {
            setDebouncedCharName(value);
        }, 300),
        []
    );

    useEffect(() => {
        debouncedSetCharName(advancedCharData.charName);
        //eslint-disable-next-line
    }, [advancedCharData.charName]);

    useEffect(() => {
        if (debouncedCharName.length > 0) {
            const searchForLoras = async () => {
                const loraFound = await potentialLoraSearch(debouncedCharName);
                setLoras(loraFound);
            };
            searchForLoras();
        }
    }, [debouncedCharName]);

    useEffect(() => {
        localStorage.setItem('advancedCharacterCreationData', JSON.stringify(advancedCharData));
    }, [advancedCharData]);

    useEffect(() => {
        const handleLanguageChange = () => {
            const newLanguage = i18n.language;
            setCurrentLanguage(newLanguage);
            localStorage.setItem('userLanguage', newLanguage);

            // Update character data with new language
            setAdvancedCharData(prevData => ({
                ...prevData,
                languageSelection: newLanguage.toUpperCase()
            }));
        };

        // Listen for language changes
        i18n.on('languageChanged', handleLanguageChange);

        // Initial language setup
        handleLanguageChange();

        // Cleanup listener
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const updateAdvancedCharacterData = useCallback((newData) => {
        setAdvancedCharData(prevData => {
            const updatedData = {...prevData, ...newData};
            if (JSON.stringify(updatedData) !== JSON.stringify(prevData)) {
                return updatedData;
            }
            return prevData;
        });
    }, []);

    useEffect(() => {
        if (advancedCharData.clonedVoiceURL && advancedCharData.clonedVoiceName) {
            updateAdvancedCharacterData({voiceSelection: 'custom'});
        }
        //eslint-disable-next-line
    }, [advancedCharData.clonedVoiceURL, advancedCharData.clonedVoiceName]);

    const clearAdvancedOptions = useCallback(() => {
        const defaultData = {
            style: '',
            gender: 'Female',
            isPublic: 'Private',
            nsfw: 'NSFW',
            charName: '',
            description: '',
            appearance: '',
            personality: '',
            scenario: '',
            dialogue: '',
            age: '',
            specialCase: '',
            loraAirUrl: '',
            loraTrainedWords: '',
            profileURL: '',
            languageSelection: upperCaseLanguage ?? 'EN',
            voiceSelection: '',
            clonedVoiceName: 'Custom Voice',
            clonedVoiceURL: ''
        };

        setAdvancedCharData(defaultData);
        setLoras([]);
        setDebouncedCharName('');
        setUseAiGeneration(false);
        setImageFile('');
        setShowPrompt(false);
        setPrompt('');
        setGeneratedProfileImage('');
        setVoiceSelection('');

        //TODO set NSFW to true and private to true

        // Clear localStorage
        localStorage.removeItem('advancedCharacterCreationData');
        //eslint-disable-next-line
    }, []);

    return (
        <AdvancedCharacterCreationContext.Provider value={{
            loras,
            advancedCharData,
            updateAdvancedCharacterData,
            useAiGeneration,
            setUseAiGeneration,
            imageFile,
            setImageFile,
            showPrompt,
            setShowPrompt,
            prompt, setPrompt, generatedProfileImage, setGeneratedProfileImage,
            selectedVoice, setVoiceSelection, clearAdvancedOptions
        }}>
            {children}
        </AdvancedCharacterCreationContext.Provider>
    );
};