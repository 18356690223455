import React, { useState, useEffect, useRef } from 'react';
import './BannerStyle.css';
import { ReactComponent as Discord } from './BannerIcons/discordbanner.svg';
import { ReactComponent as StarIcon } from './BannerIcons/buttonIcon.svg';
import { useTranslation } from "react-i18next";

const UniversalScrollBanner = ({ visibleBanners = {}, hideBanner }) => {
    const { t } = useTranslation();
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const bannerRef = useRef(null);
    const contentRef = useRef(null);
    const timerRef = useRef(null);
    const animationRef = useRef(null);
    const [bannerStyle, setBannerStyle] = useState({});
    
    // Define the available banners with proper translation handling
    const bannerTypes = [
        {
            id: 'discord',
            isVisible: visibleBanners.discord !== false,
            buttonClass: 'button-state-1',
            title: t('JoinCommunity'),
            subtitle: t('OnDiscord'),
            buttonText: t('JoinServer'),
            remindText: t('RemindJoinLater'),
            icon: <Discord />,
            handleClick: () => window.open('https://discord.gg/V4YUYRBc5M', '_blank'),
            background: 'linear-gradient(275.36deg, rgba(0, 71, 255, 0.2) -5%, rgba(0, 0, 0, 0) 59.08%), linear-gradient(74.11deg, rgba(15, 111, 255, 0.106) 18.4%, rgba(0, 0, 0, 0) 48.51%), #1A1A1A'
        },
        {
            id: 'upgrade',
            isVisible: visibleBanners.upgrade !== false,
            buttonClass: 'button-state-3',
            title: t('UpgradePlan'),
            subtitle: t('ElyzaPremium'),
            buttonText: t('UpgradeNow'),
            remindText: t('RemindUpgradeLater'),
            icon: null,
            handleClick: () => window.location.href = '/pricing',
            background: 'linear-gradient(275.76deg, rgba(73, 0, 255, 0.2) 2.12%, rgba(0, 0, 0, 0) 55.27%), linear-gradient(55.75deg, rgba(73, 0, 255, 0.2) 10.75%, rgba(0, 0, 0, 0) 60.65%), #1A1A1A'
        },
        {
            id: 'affiliate',
            isVisible: visibleBanners.affiliate !== false,
            buttonClass: 'button-state-2',
            title: t('BecomeAffiliate'),
            subtitle: t('EarnTokens'),
            buttonText: t('JoinProgram'),
            remindText: t('RemindAffiliateLater'),
            icon: null,
            handleClick: () => window.location.href = 'https://affiliates.elyza.app/',
            background: 'linear-gradient(275.36deg, rgba(255, 0, 230, 0.2) -5%, rgba(0, 0, 0, 0) 59.08%), linear-gradient(55.75deg, rgba(203, 0, 183, 0.2) 10.75%, rgba(0, 0, 0, 0) 60.65%), #1A1A1A'
        }
    ];

    // Filter out invisible banners
    const availableBanners = bannerTypes.filter(banner => banner.isVisible);

    // Set initial banner style
    useEffect(() => {
        if (availableBanners.length > 0) {
            setBannerStyle({
                background: availableBanners[currentBannerIndex].background,
                willChange: 'transform, opacity, background',
                transform: 'translateZ(0)',
                backfaceVisibility: 'hidden',
                transition: 'background 0.4s ease'
            });
        }
        //eslint-disable-next-line
    }, []);

    // Reset current index if it's out of bounds
    useEffect(() => {
        if (currentBannerIndex >= availableBanners.length && availableBanners.length > 0) {
            setCurrentBannerIndex(0);
        }
    }, [availableBanners.length, currentBannerIndex]);

    // Clean up any timers when component unmounts
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);

    // Update background when banner changes - using RAF for smoother transitions
    useEffect(() => {
        if (availableBanners.length > 0) {
            // Use request animation frame for smoother background transitions
            animationRef.current = requestAnimationFrame(() => {
                setBannerStyle(prevStyle => ({
                    ...prevStyle,
                    background: availableBanners[currentBannerIndex].background
                }));
            });
        }
        
        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [currentBannerIndex, availableBanners]);

    // Simplified transition function that works reliably
    const transitionToNextBanner = () => {
        if (isTransitioning || availableBanners.length <= 1 || !contentRef.current) return;
        
        setIsTransitioning(true);
        
        // Apply slide-out animation
        contentRef.current.className = 'banner-content banner-slide-out';
        
        // Wait for slide-out animation to complete
        timerRef.current = setTimeout(() => {
            // Update to next banner index
            setCurrentBannerIndex(prevIndex => (prevIndex + 1) % availableBanners.length);
            
            // Set a very short timeout to ensure state update has processed
            setTimeout(() => {
                // Apply slide-in animation
                if (contentRef.current) {
                    contentRef.current.className = 'banner-content banner-slide-in';
                    
                    // Wait for slide-in animation to complete
                    timerRef.current = setTimeout(() => {
                        if (contentRef.current) {
                            contentRef.current.className = 'banner-content';
                        }
                        setIsTransitioning(false);
                    }, 300);
                } else {
                    setIsTransitioning(false);
                }
            }, 20);
        }, 300);
    };

    // Auto-scroll with faster interval
    useEffect(() => {
        if (availableBanners.length <= 1) return;

        const interval = setInterval(() => {
            if (!isTransitioning) {
                transitionToNextBanner();
            }
        }, 5000);

        return () => clearInterval(interval);
        //eslint-disable-next-line
    }, [availableBanners.length, isTransitioning]);

    // Simplified banner switching that works reliably
    const switchToBanner = (index) => {
        if (index === currentBannerIndex || isTransitioning || !contentRef.current) return;
        
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        
        setIsTransitioning(true);
        
        // Apply slide-out animation
        contentRef.current.className = 'banner-content banner-slide-out';
        
        // Wait for slide-out animation to complete
        timerRef.current = setTimeout(() => {
            // Update the banner index
            setCurrentBannerIndex(index);
            
            // Set a very short timeout to ensure state update has processed
            setTimeout(() => {
                // Apply slide-in animation
                if (contentRef.current) {
                    contentRef.current.className = 'banner-content banner-slide-in';
                    
                    // Wait for slide-in animation to complete
                    timerRef.current = setTimeout(() => {
                        if (contentRef.current) {
                            contentRef.current.className = 'banner-content';
                        }
                        setIsTransitioning(false);
                    }, 300);
                } else {
                    setIsTransitioning(false);
                }
            }, 20);
        }, 300);
    };

    // If no banners are visible or available, return null
    if (availableBanners.length === 0) return null;

    const currentBanner = availableBanners[currentBannerIndex];
    
    return (
        <div 
            ref={bannerRef}
            className="banner-container"
            style={bannerStyle}
        >
            <div 
                ref={contentRef}
                className="banner-content"
                style={{ willChange: 'transform, opacity' }}
            >
                <div className="banner-left">
                    <span className="banner-title">{currentBanner.title}</span>
                    <span className="banner-subtitle">
                        {currentBanner.subtitle}
                        {currentBanner.icon && (
                            <div className='banner-icon'>
                                {currentBanner.icon}
                            </div>
                        )}
                    </span>
                </div>
                <div className="banner-right">
                    <div className="center-component-banner">
                        <button 
                            className={`banner-button ${currentBanner.buttonClass}`} 
                            onClick={currentBanner.handleClick}
                        >
                            <StarIcon className='star-icon-banner'/>
                            {currentBanner.buttonText}
                        </button>
                        <span 
                            className='sub-button-text' 
                            onClick={() => hideBanner(currentBanner.id)}
                        >
                            {currentBanner.remindText}
                        </span>
                    </div>
                </div>
            </div>
            
            {/* Banner pagination indicators - improved for mobile */}
            {availableBanners.length > 1 && (
                <div className="banner-pagination">
                    {availableBanners.map((banner, index) => (
                        <span 
                            key={banner.id} 
                            className={`pagination-dot ${index === currentBannerIndex ? 'active' : ''}`}
                            onClick={() => switchToBanner(index)}
                            title={banner.title}
                            aria-label={`Switch to ${banner.title} banner`}
                            style={{ display: 'block' }} // Force display inline
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default UniversalScrollBanner; 