import React, {useEffect, useMemo, useState} from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {useProfileData} from "../Context/ProfileDataContext";
import {useNavigate} from "react-router-dom";
import {getUserAccessToken, getUserID} from "../../App";
import {useChat} from "../Context/ChatContext";
import {useTranslation} from "react-i18next";
import './DropDownGeneral.css'
import UpgradeNewModel from "../Notifications/Upsells/UpgradeNewModel";
import UpdatedMessageLimitUpsell from "../Notifications/UpdatedUpsells/UpdatedMessageLimit";

const CustomDropdown = ({
                            value,
                            setValue,
                            setLlmModel,
                            modelSwitchExternalCache,
                            setModelSwitchExternalCache,
                            selectedLanguage,
                            setSelectedLanguage,
                            setLoginError
                        }) => {
    const navigate = useNavigate();
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [messageLimitError, setMessageLimitError] = useState(false);

    const {profileData, userBonusMessages} = useProfileData();
    const {wasRecentChatUpdated, setWasRecentChatUpdated} = useChat();

    const isFreeUser = profileData.subscription_data.tier === "FREE";

    // const hasReachedMessageLimit = isFreeUser && profileData.message_count >= 50 && userBonusMessages <= 0;

    const premiumMessagesLeft = Math.max(0, Math.min(
        100,
        30 - Math.max(0, parseInt(profileData.message_count || 0, 10)) +
        Math.max(0, parseInt(profileData.bonus_messages || 0, 10))
    ));

    const isPremiumModel = useMemo(() => {
        return ['Elyza Classic', 'Elyza Modern', 'Elyza Polyglot'].includes(value);
    }, [value]);

    const canUsePremiumModels = !isFreeUser || premiumMessagesLeft > 0;

    const identityName = window.location.pathname.substring(6);

    const {t} = useTranslation();

    useEffect(() => {
        if (wasRecentChatUpdated) {
            Object.keys(localStorage).forEach(key => {
                if (key.startsWith('model_')) {
                    localStorage.removeItem(key);
                }
            });
            setWasRecentChatUpdated(false);
        }

        const storedModel = localStorage.getItem(`model_${identityName}`);
        if (storedModel) {
            setModelSwitchExternalCache(storedModel);
            setValue(storedModel);
            setLlmModel(storedModel);
        } else {
            setModelSwitchExternalCache(value);
        }
    }, [wasRecentChatUpdated, identityName, setValue, setLlmModel, setModelSwitchExternalCache, value, setWasRecentChatUpdated]);

    const handleSwitchToFree = async () => {
        const newModel = 'Elyza Free';
        setLlmModel(newModel);
        setValue(newModel);
        setModelSwitchExternalCache(newModel);
        setMessageLimitError(false);

        localStorage.setItem(`model_${identityName}`, newModel);

        try {
            await updateSettingsOnServer(newModel);
        } catch (error) {
            // console.error('Error updating settings:', error);
        }
    };

    const updateSettingsOnServer = async (newModel) => {
        const userID = await getUserID();

        const response = await fetch('https://api.elyza.app/v1/change-settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({
                userId: userID,
                identityName: identityName,
                newModel: newModel,
            })
        });

        if (!response.ok) {
            throw new Error('Failed to update settings');
        }
    };

    const options = useMemo(() => {
        const baseOptions = [
            {value: 'Elyza Free', label: t('elyzaFree')},
            {value: 'Elyza Classic', label: t('elyzaClassic')},
            {value: 'Elyza Polyglot', label: t('elyzaPolyglot')},
        ];

        // if (!isFreeUser || userBonusMessages > 0 || !canUsePremiumModels) {
        //     baseOptions.push({value: 'Elyza Modern', label: t('elyzaAdvanced')});
        // }

        if (canUsePremiumModels) {
            baseOptions.push(
                {value: 'Elyza Modern', label: t('elyzaAdvanced')}
            );
        }

        return baseOptions;
        //eslint-disable-next-line
    }, [isFreeUser, userBonusMessages, t])

    const languages = [
        {value: 'es', label: '🇪🇸 Español'},
        {value: 'fr', label: '🇫🇷 Français'},
        {value: 'ru', label: '🇷🇺 Русский'},
        {value: 'de', label: '🇩🇪 Deutsch'},
        {value: 'it', label: '🇮🇹 Italiano'},
        {value: 'pl', label: '🇵🇱 Polski'},
        {value: 'pt', label: '🇵🇹 Portuguese'},
        {value: 'ja', label: '🇯🇵 日本語'},
        {value: 'ko', label: '🇰🇷 한국어'},
        {value: 'zh', label: '🇨🇳 中文 (简体)'}
    ];

    useEffect(() => {
        if (isFreeUser) {
            setMessageLimitError(premiumMessagesLeft === 0 && (value === 'Elyza Classic' || value === 'Elyza Modern' || value === 'Elyza Polyglot'));
        }
    }, [value, premiumMessagesLeft, isFreeUser]);

    const handleChange = async (option) => {
        const newModel = option.value;
        // const isPremiumModel = ['Elyza Classic', 'Elyza Modern', 'Elyza Polyglot'].includes(newModel);

        // Premium user can switch to any model freely
        if (!isFreeUser) {
            setModelSwitchExternalCache(newModel);
            setValue(newModel);
            setLlmModel(newModel);
            localStorage.setItem(`model_${identityName}`, newModel);

            try {
                await updateSettingsOnServer(newModel);
            } catch (error) {
                console.error('Error updating settings:', error);
            }
            return;
        }

        // Free user logic
        if (isPremiumModel) {
            // Check if they have any messages left (free + bonus)
            if (premiumMessagesLeft <= 0) {
                setMessageLimitError(true);
                setSubscriptionError(true);
                return;
            }
        }

        // If we get here, either:
        // 1. User is switching to Elyza Free
        // 2. User is free but has remaining messages and is switching to premium
        setModelSwitchExternalCache(newModel);
        setValue(newModel);
        setLlmModel(newModel);
        localStorage.setItem(`model_${identityName}`, newModel);

        try {
            await updateSettingsOnServer(newModel);
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };

    const handleLanguageChange = (option) => {
        setSelectedLanguage(option.value);
        localStorage.setItem(`language_${identityName}`, option.value);
    };

    useEffect(() => {
        const storedLanguage = localStorage.getItem(`language_${identityName}`);
        if (storedLanguage) {
            setSelectedLanguage(storedLanguage);
        }
    }, [identityName, setSelectedLanguage]);

    return (
        <>
            {isFreeUser && (
                <>
                    {subscriptionError && <UpgradeNewModel isOpen={subscriptionError} onClose={(confirm) => {
                        setSubscriptionError(false);
                        if (confirm) {
                            navigate('/pricing');
                        }
                    }}/>}

                    {messageLimitError && <UpdatedMessageLimitUpsell
                        isOpen={messageLimitError}
                        onClose={() => setMessageLimitError(false)}
                        onSwitchFree={handleSwitchToFree}
                        model={value}
                        modelSwitchExternalCache={modelSwitchExternalCache}
                        setLoginError={setLoginError}
                    />}
                </>
            )}
            <Dropdown
                options={options}
                value={value}
                onChange={handleChange}
                className="model-dropdown"
                controlClassName="model-dropdown-control"
                menuClassName="model-dropdown-menu"
                arrowClassName="model-dropdown-arrow"
            />

            {/*{(value === 'Elyza Classic' || value === 'Elyza Modern') && isFreeUser &&*/}
            {/*    <div className="bonus-messages-counter">*/}
            {/*        {userBonusMessages}*/}
            {/*        <RewardMessage/>*/}
            {/*    </div>*/}
            {/*}*/}

            {value === 'Elyza Polyglot' && (
                <>
                    <Dropdown
                        options={languages}
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        className="language-dropdown"
                        controlClassName="language-dropdown-control"
                        menuClassName="language-dropdown-menu"
                        arrowClassName="language-dropdown-arrow"
                    />
                    {/*{isFreeUser &&*/}
                    {/*    <div className="bonus-messages-counter">*/}
                    {/*        {userBonusMessages}*/}
                    {/*        <RewardMessage/>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </>
            )}


        </>
    );
};

export default CustomDropdown;