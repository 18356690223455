import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import { getUserAccessToken, getUserID, userIDExists } from "../../App";
import { addToFavorites, removeFromFavorites } from "./CharacterCardFunctions";
import { ReactComponent as StarIcon } from "./IconsSub/AIGF.svg";
import { ReactComponent as FavoriteIcon } from "./IconsSub/favorite.svg";
import { ReactComponent as SelectedFavoriteIcon } from "./IconsSub/selectedFavorite.svg";
import { ReactComponent as ShareIcon } from "./IconsSub/share.svg";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {useTranslation} from "react-i18next";


export const GirlfriendButton = ({ setIsUserLoggedIn, identityName, initialIsFavorite }) => {
    const [internalLimit, setInternalLimit] = useState(false);
    const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 720);
    const navigate = useNavigate();
    const [showSharePopup, setShowSharePopup] = useState(false);
    const {profileData} = useProfileData();
    const location = useLocation();
    
    const {t} = useTranslation();

    const SharePopup = ({ show }) => (
        <div className={`share-popup ${show ? 'show' : ''}`}>
            {t('urlCopied')}
        </div>
    );

    useEffect(() => {
        setIsFavorite(initialIsFavorite);
    }, [initialIsFavorite]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 720);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists();
        if (!loggedIn) setIsUserLoggedIn(true);
        return loggedIn;
    };

    const {language} = useProfileData();

    const mapLanguageCodeToEnglishName = (code) => {
        return languageCodeToEnglishName[code] || code;
    };

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    };

    let correctLanguage = mapLanguageCodeToEnglishName(language);

    const handleStartChat = async () => {

        if (internalLimit) return;
        if (!await validateIsLoggedIn()) return;

        setInternalLimit(true);
        const id = await getUserID();
        if (id === undefined) {
            setIsUserLoggedIn(false);
            setInternalLimit(false);
            return;
        }

        const urlParams = new URLSearchParams(location.search);
        const sharedBy = urlParams.get('sharedBy') || '';

        await fetch('https://api.elyza.app/v1/create-character', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: await getUserAccessToken(),
            },
            body: JSON.stringify({
                identityName: identityName,
                sharedBy: sharedBy,
                language: correctLanguage
            }),
        });

        navigate(`/chat/${identityName}`, { state: { autoSelect: true } });
        setInternalLimit(false);
    };

    const handleFavoriteClick = async () => {

        if (await validateIsLoggedIn()) {
            setIsFavorite(prev => !prev);
            if (!isFavorite) {
                await addToFavorites(identityName);
            } else {
                await removeFromFavorites(identityName);
            }
        }
    };

    const handleShareClick = () => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('sharedBy', encodeURIComponent(profileData.tagname));
        const shareUrl = currentUrl.toString();

        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                setShowSharePopup(true);
                setTimeout(() => setShowSharePopup(false), 2000);
            })
            .catch(err => {
                console.error('Failed to copy URL: ', err);
            });
    };

    const MobileButton = () => (
        <div className="character-card-button-container">
            <button
                onClick={handleFavoriteClick}
                className='share-favorite-button'>
                {isFavorite ? <SelectedFavoriteIcon /> : <FavoriteIcon />}
            </button>

            <button
                onClick={handleStartChat}
                className='ai-girlfriend-button'
            >
                <StarIcon />
                {t('startChat')}
            </button>

            <div className="share-button-container">
                <SharePopup show={showSharePopup} />
                <button
                    onClick={handleShareClick}
                    className='share-favorite-button'>
                    <ShareIcon />
                </button>
            </div>
        </div>
    );

    const DesktopButton = () => (
        <div className="character-card-button-container">
            <button
                onClick={handleStartChat}
                className='ai-girlfriend-button'
            >
                <StarIcon />
                {t('startChat')}
            </button>
            <div className="share-favorite-button-container">
                <button
                    onClick={handleFavoriteClick}
                    className='share-favorite-button'>
                    {isFavorite ? <SelectedFavoriteIcon /> : <FavoriteIcon />}
                </button>
                <div className="share-button-container">
                    <SharePopup show={showSharePopup} />
                    <button
                        onClick={handleShareClick}
                        className='share-favorite-button'>
                        <ShareIcon />
                    </button>
                </div>
            </div>
        </div>
    );

    return isMobile ? <MobileButton /> : <DesktopButton />;
};