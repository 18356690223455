import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {ChatPage} from './pages/ChatPage/ChatPage';
import {createClient} from '@supabase/supabase-js';
import {SalesPage} from "./pages/SalesPage";
import {MenuPageUpdated} from "./pages/NewMainPage";
import NewPricingPage from './pages/PricingPage/PricingPage';
import CreateCharacterNew from './pages/SimpleCharacterCreation/SimpleCharacterCreation';
import TermsOfService from './pages/LegalPages/TermsOfUse';
import {UpdatePasswordPage} from "./components/Forms/UpdatePasswordPage";
import PrivacyPolicy from './pages/LegalPages/PrivacyPolicy';
import PublicPolicy from './pages/LegalPages/PublicContent';
import LegalNavigation from "./pages/LegalPages/LegalNavigation";
import DMCA from "./pages/LegalPages/DMCA";
import ErrorPage from './pages/ErrorPage/404Page';
import MicroTransactions from "./pages/MicroTransactions";
import React, {useEffect, useRef, useState} from "react";
import {GrowthBook, GrowthBookProvider} from "@growthbook/growthbook-react";
// import ReferralSuccessNotification from "./components/Notifications/ReferralSuccessNotification";
import ImageGenerationNotification from "./components/Notifications/ImageGenerationNotification";
import MaintenanceNotification from "./components/Notifications/MaintenanceNotification";
import {TimerProvider} from "./components/Context/TimerContext";
import {ProfileDataProvider} from "./components/Context/ProfileDataContext";
import Legal from "./pages/LegalPages/Legal";
import ContentRemoval from "./pages/LegalPages/ContentRemoval";
import PlanCongrats from "./components/Notifications/PlanCongrats";
import MicroTransCongrats from "./components/Notifications/MicroTransCongrats";
import {ChatProvider} from "./components/Context/ChatContext";
import CharacterCreateImagePage from "./pages/CharacterCreateImagePage";
import JoinDiscordCommunity from "./components/Notifications/SpecialNotifications/JoinDiscordCommunity";
import CharacterCard from "./pages/CharacterCard/CharacterCard";
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {I18nextProvider} from 'react-i18next';
import UserPage from "./pages/UserPage/UserPage";
import {CharacterCreationProvider} from "./pages/SimpleCharacterCreation/CharacterCreationContext";
import AdvancedCharacterCreation from "./pages/AdvancedCharacterCreation/AdvancedCharacterCreation";
import {AdvancedCharacterCreationProvider} from "./pages/AdvancedCharacterCreation/AdvancedCharacterContext";
// import UltimateNotificationTester from "./components/Notifications/UltimateNotificationTester";
import GenerateImagePage from "./pages/ImageGenerationPage/GenerateImagePage";
import {UserSettingProvider} from "./components/UserSettings/UserSettingContext";
import UpgradeNotification from "./components/Notifications/Upsells/UpgradeNotification";
import UpgradeSaleNotification from "./components/Notifications/Upsells/UpgradeSaleNotification";
import PublishSuccess from "./components/Modals/PublicCharModals/PublishSuccess";
import PublishDenied from "./components/Modals/PublicCharModals/PublishDenied";
import CharacterFunnel from "./components/Sales&SEO/SalesCharCreator/CharacterFunnel";
import {FunnelCharacterProvider} from "./components/Sales&SEO/SalesCharCreator/FunnelContext";
import {ChatDropdownProvider} from "./pages/ChatPage/ChatSettingsContext";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ['en', 'es', 'ru', 'fr', 'zh', 'ja', 'ko', 'de', 'it', 'pl', 'pt'],
        detection: {
            order: ['navigator', 'htmlTag', 'querystring', 'cookie', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
        // debug: true  // Add this line for debugging
    });

const SupaUrl = process.env.REACT_APP_SUPABASE_URL;
const SupaKey = process.env.REACT_APP_SUPABASE_KEY;
const SupaBearerKey = `Bearer ${SupaKey}`;

export const growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: "sdk-J1n6oktNDDAEDMC",
    trackingCallback: (experiment, result) => {
        // TODO: Use your real analytics tracking system
        console.log("Viewed Experiment", {
            experimentId: experiment.key,
            variationId: result.key
        });
    }
});

export const supabase = createClient(SupaUrl, SupaKey);

function App() {
    const [notifications, setNotifications] = useState(null);
    const [lastFetchTime, setLastFetchTime] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Load features asynchronously when the app renders
        growthbook.init({streaming: true});
    }, []);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    useEffect(() => {
        const storedLastFetchTime = localStorage.getItem('lastFetchTime');
        setLastFetchTime(storedLastFetchTime ? parseInt(storedLastFetchTime) : null);
    }, []);

    const activityTimeoutRef = useRef(null);
    const [isActive, setIsActive] = useState(true); // To track page visibility

    // Function to reset activity timeout
    const resetActivityTimeout = () => {
        if (activityTimeoutRef.current) {
            clearTimeout(activityTimeoutRef.current);
        }
        activityTimeoutRef.current = setTimeout(() => {
            // No activity for 1.5 minutes
            // You can set a state or perform actions accordingly
            setIsActive(false);
        }, 0.75 * 60 * 1000); // 45 seconds
    };

    useEffect(() => {
        // Initialize activity timeout on mount
        resetActivityTimeout();

        // List of events to consider as user activity
        const activityEvents = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'scroll'];

        // Event listener to reset the activity timeout
        const handleActivity = () => {
            resetActivityTimeout();
            setIsActive(true); // User is active again
        };

        // Add event listeners for user activity
        activityEvents.forEach((event) => {
            window.addEventListener(event, handleActivity);
        });

        // Cleanup on unmount
        return () => {
            if (activityTimeoutRef.current) {
                clearTimeout(activityTimeoutRef.current);
            }
            activityEvents.forEach((event) => {
                window.removeEventListener(event, handleActivity);
            });
        };
    }, []);

    useEffect(() => {
        const fetchNotifications = async () => {
            if (!isLoggedIn) {
                return;
            }

            try {
                const response = await fetch('https://api.elyza.app/v1/user-notifications', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({
                        userId: await getUserID(),
                    })
                });
                const data = await response.json();

                if (data.notifications.length > 0) {
                    setNotifications(data.notifications);
                } else {
                    setNotifications(null);
                }

                setLastFetchTime(Date.now());
                localStorage.setItem('lastFetchTime', Date.now().toString());
            } catch (error) {
                // Handle error appropriately
                console.error('Error fetching notifications:', error);
            }
        };

        const thirtySecondsInMs = 30 * 1000;
        const timeSinceLastFetch = lastFetchTime ? Date.now() - lastFetchTime : Infinity;

        // Conditions to fetch:
        // 1. Notifications are not already present
        // 2. Tab is active
        // 3. User has been active within the last 1.5 minutes
        if (!notifications && isActive && timeSinceLastFetch >= thirtySecondsInMs) {
            fetchNotifications();
        } else if (!notifications && isActive) {
            const delay = thirtySecondsInMs - timeSinceLastFetch;
            const timerId = setTimeout(fetchNotifications, delay);
            return () => clearTimeout(timerId); // Cleanup timeout if dependencies change
        }
        // If notifications exist, do not fetch
    }, [lastFetchTime, isLoggedIn, notifications, isActive]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        const referralCode = queryParams.get('referral');
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
        }

        const affCode = queryParams.get('aff');
        if (affCode) {
            localStorage.setItem('affCode', affCode);
        }
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const clickAduId = queryParams.get('clickadu_id');
        const campaignCode = queryParams.get('campaign');
        const targetType = queryParams.get('type');
        const giftCampaign = queryParams.get('gift');
        const typesArray = targetType ? targetType.split(',').map(type => {
            switch (type.trim()) { // Trim to remove any accidental leading/trailing whitespace
                case 'r':
                    return 'Realistic';
                case 'a':
                    return 'Anime';
                case 'f':
                    return 'Furry';
                case 'v':
                    return 'Futanari';
                default:
                    return type; // Return the type as is if it doesn't match any case
            }
        }) : [];

        if (clickAduId) {
            localStorage.setItem('campaignCode', `clickadu-${clickAduId}`);
        }

        if (campaignCode) {
            localStorage.setItem('campaignCode', campaignCode);
            renderCampaignClick(campaignCode).then((response) => {
            });
        }

        if (giftCampaign) {
            // Remove gift campaign from URL
            // queryParams.delete('gift');
            // const newUrl = window.location.pathname + (queryParams.toString() ? `?${queryParams.toString()}` : '');
            // window.history.replaceState({}, document.title, newUrl);
            
            // Store gift campaign in localStorage
            // localStorage.setItem('giftCampaign', giftCampaign);
            
            // Send request to handle gift campaign
            
            renderGiftCampaign(giftCampaign).then((response) => {
            });
        }

        if (targetType) {
            localStorage.setItem('type', JSON.stringify(typesArray));
        }
    }, []);

    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        // Function to handle location changes
        const handleLocationChange = () => {
            if (currentPath !== window.location.pathname) {
                if (currentPath.includes('/create-character')) {
                    if (window.location.pathname.includes('/advanced-creator')) {
                        window.scrollTo(0, 0);
                    }
                } else if (currentPath.includes('/advanced-creator')) {
                    if (window.location.pathname.includes('/create-character')) {
                        window.scrollTo(0, 0);
                    }
                }
            }
            setCurrentPath(window.location.pathname);
        };

        // Listen for popstate event (browser back/forward)
        window.addEventListener('popstate', handleLocationChange);

        // Listen for pushstate/replacestate
        const originalPushState = window.history.pushState;
        const originalReplaceState = window.history.replaceState;

        window.history.pushState = function () {
            originalPushState.apply(this, arguments);
            handleLocationChange();
        };

        window.history.replaceState = function () {
            originalReplaceState.apply(this, arguments);
            handleLocationChange();
        };

        // Cleanup function
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
            window.history.pushState = originalPushState;
            window.history.replaceState = originalReplaceState;
        };
    }, [currentPath]);

    const renderCampaignClick = async (campaignCode) => {
        try {
            const response = await fetch('https://api.elyza.app/v1/render-campaign-click', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    campaign: campaignCode
                })
            });
            return await response.json();
        } catch (error) {
            // console.error('Error rendering campaign click:', error);
        }
    }
    
    const renderGiftCampaign = async (giftCampaign) => {
        fetch('https://api.elyza.app/v1/render-gift-campaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({
                campaign: giftCampaign
            })
        }).then(response => {
            if (!response.ok) {
                console.error('Failed to process gift campaign');
            }
            return response.json();
        }).catch(error => {
            console.error('Error processing gift campaign:', error);
        });
    }

    useEffect(() => {
        setTimeout(() => {
            let url = new URL(window.location.href);
            url.searchParams.delete('aff');
            url.searchParams.delete('campaign');
            url.searchParams.delete('referral');
            window.history.replaceState({}, document.title, url);
        }, 1000);
    }, []);


    return (
        <div>
            <div id="modal-root"></div>
            <GrowthBookProvider growthbook={growthbook}>
                <I18nextProvider i18n={i18n}>
                        <FunnelCharacterProvider>
                            <ChatDropdownProvider>
                                <CharacterCreationProvider>
                                    <AdvancedCharacterCreationProvider>
                                        <ProfileDataProvider>
                                            {/*<ChatFunctionPasser>*/}
                                            {notifications && notifications.length > 0 && (
                                                <div>
                                                    {notifications.map((notification) => (
                                                        <div key={notification.id}>
                                                            {renderNotificationComponent(notification.notification_type, notification.id, notification.reward_type, notification.reward_amount, notifications, setNotifications, notification.message)}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            <UserSettingProvider>
                                                <ChatProvider>
                                                    <TimerProvider>
                                                        <Router>
                                                            <Routes>
                                                                <Route path="/main" element={<Navigate to="/"/>}/>

                                                                <Route path="/" element={<MenuPageUpdated/>}/>

                                                                <Route path="/c/:identityName"
                                                                       element={<CharacterCard/>}/>
                                                                <Route path="/user/:userId" element={<UserPage/>}/>

                                                                <Route path="/generate" element={<GenerateImagePage/>}/>

                                                                <Route path="/chat" element={<ChatPage/>}/>
                                                                <Route path="/chat/:characterId" element={<ChatPage/>}/>
                                                                <Route path="/chat/gc/:chatId" element={<ChatPage/>}/>
                                                                <Route path="/update-password"
                                                                       element={<UpdatePasswordPage/>}/>
                                                                <Route path="/sales" element={<SalesPage/>}/>
                                                                <Route path="/addons" element={<MicroTransactions/>}/>
                                                                {/*<Route path="/verify-email" element={<EmailConfirmation/>}/>*/}

                                                            <Route path="/legal" element={<LegalNavigation/>}/>
                                                            <Route path="/legal/dmca" element={<DMCA/>}/>
                                                            <Route path="/legal/other" element={<Legal/>}/>
                                                            <Route path="/legal/content" element={<ContentRemoval/>}/>
                                                            <Route path="/legal/terms-of-use" element={<TermsOfService/>}/>
                                                            <Route path="/legal/public-content" element={<PublicPolicy/>}/>
                                                            <Route path="/legal/privacy-policy" element={<PrivacyPolicy/>}/>
                                                            <Route path="/pricing" element={<NewPricingPage/>}/>

                                                                {/*<Route path="/pricing-sale" element={<SpecialPricingPage/>}/>*/}

                                                                <Route path="/create-character"
                                                                   element={<CreateCharacterNew/>}/>
                                                            <Route path="/advanced-creator"
                                                                   element={<AdvancedCharacterCreation/>}/>


                                                                <Route path="*" element={<ErrorPage/>}/>
                                                                <Route path="/gallery/:id"
                                                                       element={<CharacterCreateImagePage/>}/>
                                                                {/* <Route path="/dev"
                                                                       element={<UltimateNotificationTester/>}/> */}


                                                                <Route path="/create-trans"
                                                                       element={<CharacterFunnel
                                                                           version="trannyGeneral"/>}/>
                                                                <Route path="/create-feet"
                                                                       element={<CharacterFunnel
                                                                           version="feetGeneral"/>}/>
                                                                {/*<Route path="/devdevdev"*/}
                                                                {/*       element={<UltimateNotificationTester/>}/>*/}

                                                            </Routes>
                                                        </Router>
                                                    </TimerProvider>
                                                </ChatProvider>
                                            </UserSettingProvider>
                                            {/*</ChatFunctionPasser>*/}
                                        </ProfileDataProvider>
                                    </AdvancedCharacterCreationProvider>
                                </CharacterCreationProvider>
                            </ChatDropdownProvider>
                        </FunnelCharacterProvider>
                </I18nextProvider>
            </GrowthBookProvider>
        </div>
    );
}

const renderNotificationComponent = (type, notificationId, rewardType, rewardAmount, notifications, setNotifications, message = '') => {
    switch (type) {
        // case 'REFERRAL':
        //     return <ReferralSuccessNotification onClose={async () => {
        //         // remove notification with id
        //         setNotifications(notifications.filter(notification => notification.id !== notificationId));
        //         await receiveNotification(notificationId)
        //         // TODO: Navigate to referral store
        //     }} purchase={false} coins={rewardAmount} type={'COINS'} isOpen={true}/>;
        // case 'REFERRAL_PURCHASE':
        //     return <ReferralSuccessNotification onClose={async () => {
        //         setNotifications(notifications.filter(notification => notification.id !== notificationId));
        //         await receiveNotification(notificationId)
        //         // TODO: Navigate to referral store
        //     }} purchase={true} coins={rewardAmount} type={'COINS'} isOpen={true}/>;
        case 'MESSAGE':
            return <MaintenanceNotification onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'UPSELL':
            return <UpgradeNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'REIMBURSE':
            return <ImageGenerationNotification onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
            }} isOpen={true} images={rewardAmount}/>;
        case 'REWARD':
            return <UpgradeNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'DISCORD':
            return <JoinDiscordCommunity onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'SUBSCRIPTION':
            // TODO: ADD BONUS OFFER BANNER BASED ON PLAN
            return <PlanCongrats onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                // if (redirect) {
                //     window.location.href = 'https://elyza.app/pricing';
                // }
                await receiveNotification(notificationId)
            }} isOpen={true} plan={message}/>;
        case 'MICROTRANSACTION':
            return <MicroTransCongrats onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                // if (redirect) {
                //     window.location.href = 'https://elyza.app/addons';
                // }
                await receiveNotification(notificationId)
            }} isOpen={true} images={parseInt(message.split('|')[0], 10)}
                                       audios={parseInt(message.split('|')[1], 10)}
                                       tokens={parseInt(message.split('|')[2], 10)}/>;
        // return <DailyGiftNofification/>;
        case 'DISCOUNT':
            return <UpgradeSaleNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true} percent={rewardAmount}/>;
        case 'PUBLICATION_APPROVED':
            return <PublishSuccess onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId);
            }} isOpen={true} characterName={message}/>;
        case 'PUBLICATION_DENIED':
            return <PublishDenied onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId);
            }} isOpen={true} characterName={message.split('|')[0]} message={message.split('|').slice(1).join('|')}/>;
        default:
            return <UpgradeNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
    }
};

const receiveNotification = async (notificationId) => {
    try {
        const response = await fetch('https://api.elyza.app/v1/redeem-notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({
                userId: await getUserID(),
                notificationId,
            })
        });
        return await response.json();
    } catch (error) {
        // console.error('Error receiving notification:', error);
    }
}

export const getSessionResult = async () => {
    let result = await supabase.auth.getSession();
    if (result.error || !result.data.session) {
        result = await supabase.auth.refreshSession();
    }
    return result;
}

export const getUserID = async () => {
    try {
        const result = await getSessionResult();
        return result.data.session ? result.data.session.user.id : null;
    } catch (error) {
        console.error('No user found', error);
        await supabase.auth.signOut();
        window.location.href = '/';
        return null;
    }
}

export const getUserEmail = async () => {
    const result = await getSessionResult();
    return result.data.session ? result.data.session.user.email : null;
}

export const userIDExists = async () => {
    const result = await getSessionResult();
    return result.error === null && result.data.session !== null;
}

export const getUserAccessToken = async () => {
    if (await userIDExists() === false) {
        return SupaBearerKey;
    }

    const result = await getSessionResult();
    const session = result.data.session;
    return 'Bearer ' + session.access_token;
}

export const isLoggedIn = async () => {
    const loggedIn = await userIDExists();
    return loggedIn;
}

export default App;