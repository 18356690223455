import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import { ReactComponent as UnlockIcon } from "../MainMenuPage/icons/unlock.svg";
import { AuthModal } from "../Forms/AuthModal";
import { useTranslation } from "react-i18next";


const SignUpToUseGeneral = ({isOpen, onClose }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const handleUnlockClick = () => {
        setShowModal(true);
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (<div
            className="modal-backdrop"
            style={{
                zIndex: '9999',
                alignItems: 'center'
            }}
        >
            <div
                className="modal-content-notification"
                style={{
                    zIndex: '10',
                    borderRadius: '16px',
                }}
            >
                <div className='notification-label'>
                    {t('signUpToSeeMore')}
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        {t('getUnlimitedMessages')}
                        <span className="colored-purple-text"> {t('unlimitedMessagesSmall')} </span>
                        {t('andCreateCompanion')}
                        <br /><br />
                        {t('otherFeatures')}
                    </div>
                </div>

                <button
                    onClick={handleUnlockClick}
                    className="notification-button-main"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}
                >
                    <UnlockIcon />
                    {t('unlockNow')}
                </button>
            </div>
            {showModal && <AuthModal onClose={() => setShowModal(false)} />}
        </div>),
        document.body
    );
};

export default SignUpToUseGeneral;