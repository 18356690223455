import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as HomeIcon} from '../MainMenuPage/icons/HomeIcon.svg';
import {ReactComponent as ElyzaCoin} from '../RewardScreen/RewardIcons/elyzaCoin.svg';
import {ReactComponent as ArrowRight} from '../MainMenuPage/MobileNavigation/icons/arrow.svg';
// import {ReactComponent as ProfileIcon} from '../MainMenuPage/MobileNavigation/icons/profileIcon.svg';
import {ReactComponent as SupportIcon} from '../MainMenuPage/MobileNavigation/icons/supportIcon.svg';
import {ReactComponent as HiddenGemIcon} from '../MainMenuPage/MobileNavigation/icons/HiddenGemIcon.svg';
// import {ReactComponent as DiscordIcon} from './icons/discordIcon.svg';
import {useTranslation} from "react-i18next";

import {useProfileData} from "../Context/ProfileDataContext";
import {useUserSettings} from "../UserSettings/UserSettingContext";
import {ReactComponent as ShareIcon} from '../../icons/share.svg';
import {ReactComponent as GalleryIcon} from "../../icons/folderImages.svg";
import {ReactComponent as CustomizationIcon} from "../../components/ChatComponents/ChatIcons/customization-icon.svg";
import {ReactComponent as ChatIcon} from '../MainMenuPage/icons/ChatIcon.svg';

const MobileChatSlideMenu = ({isOpen, onClose, isLoggedIn, setIsAuthModalOpen, setShowChatMainMenu, handleShareOpen, handleCustomizationModal, handleGallerySwitch}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {profileData, userTokens} = useProfileData();
    const { toggleSupportModal} = useUserSettings();
    const menuRef = useRef(null);
    const [isAnimatingOut, setIsAnimatingOut] = useState(false);

    const handleItemClick = (item) => {
        if (item.requiresLogin && !isLoggedIn) {
            setIsAuthModalOpen(true);
        } else {
            item.action();
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = 'unset';
        };
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        setIsAnimatingOut(true);
        setTimeout(() => {
            setIsAnimatingOut(false);
            onClose();
        }, 300);
    };

    const handleUsernameClick = (tagName) => {
        handleClose();
        navigate(`/user/${tagName}`);
    };

    const isPremiumShiny = profileData.subscription_data.tier === 'FREE'

    const menuItems = [
        {
            icon: <HomeIcon/>,
            label:  t('Home'),
            path: '/',
            action: () => {
                handleClose();
                navigate('/');
            }
        },
        {
            icon: <ChatIcon/>,
            label: t('viewAllChats'),
            path: '/chat',
            action: () => {
                handleClose();
                setShowChatMainMenu(false);
            }
        },
        // {
        //     icon: <ProfileIcon/>,
        //     label: t('YourProfile'),
        //     action: () => {
        //         trackButtonClick('profile_menu_option_click', {
        //             option: 'profile_edit',
        //             source: 'mobile_slide_menu'
        //         });
        //         toggleProfileEditModal();
        //         handleClose();
        //     },
        //     requiresLogin: true
        // },

        {
            icon: <GalleryIcon/>,
            label: t('Gallery'),
            action: () => {
                handleClose();
                handleGallerySwitch();
            }
        },
        {
            icon: <CustomizationIcon/>,
            label: t('Customizations'),
            path: '/chat',
            action: () => {
                handleClose();
                handleCustomizationModal();
            }
        },

        {
            icon: <ShareIcon/>,
            label: t('shareThisChat'),
            path: '/chat',
            action: () => {
                handleClose();
                handleShareOpen();
            }
        },

        {
            icon: <HiddenGemIcon/>,
            label: t('getPremium'),
            path: '/pricing',
            action: () => {
                handleClose();
                navigate('/pricing');
            },
            isPremium: isPremiumShiny
        },
        {
            icon: <SupportIcon/>,
            label: t('Support'),
            action: () => {
                toggleSupportModal();
                handleClose();
            },
            requiresLogin: true
        },
        // {
        //     icon: <SignOutIcon/>,
        //     label: t('SignOut'),
        //     action: handleSignOut,
        //     requiresLogin: true
        // },
    ];

    if (!isOpen && !isAnimatingOut) return null;

    return (
        <>

            <div className="mobile-menu-overlay"
                 style={{marginTop: '85px'}}
                 onClick={onClose}>
                <div
                    className={`mobile-menu-container ${isOpen && !isAnimatingOut ? 'open' : 'pop-out'}`}
                    onClick={e => e.stopPropagation()}
                    style={{backgroundColor: '#0d0d0d'}}
                    ref={menuRef}
                >
                    {isLoggedIn && (
                        <>
                            <div className="control-menu-header"
                                 style={{
                                     paddingLeft: '10px',
                                     paddingRight: '10px',
                                     paddingTop: '10px',
                                     paddingBottom: '5px'
                                 }}
                                 onClick={() => handleUsernameClick(profileData?.tagname)}
                            >
                                <div className="user-avatar-profile-control-menu">
                                    <img
                                        src={profileData?.profile}
                                        alt=''
                                        className='user-avatar-profile-control-menu'
                                    />
                                </div>
                                <div className="profile-control-menu-username">
                                    @{profileData?.tagname}
                                </div>
                                <div className="profile-control-menu-arrow">
                                    <ArrowRight/>
                                </div>
                            </div>

                            <div className="line-container"
                                 style={{paddingLeft: '10px', paddingRight: '10px'}}
                            >
                                <span className="text-line-container">{t('Balance')}</span>
                                <div className="line"></div>
                            </div>

                            <div className="balance-counter-main"
                                 style={{
                                     paddingLeft: '20px',
                                     paddingRight: '20px',
                                     paddingTop: '10px',
                                     paddingBottom: '10px'
                                 }}
                            >
                                <div className="profile-count-counter"
                                     style={{color: '#fff'}}
                                >
                                    {userTokens}
                                    <ElyzaCoin/>
                                </div>
                                <Link
                                    to="/addons"
                                    className="get-more-tokens-sign"
                                >
                                    {t('GetMoreTokens')}
                                </Link>
                            </div>

                            <div className="line-container"
                                 style={{paddingLeft: '10px', paddingRight: '10px'}}
                            >
                                <span className="text-line-container">{t('Options')}</span>
                                <div className="line"></div>
                            </div>
                        </>
                    )}

                    <div className="mobile-menu-items">
                        {menuItems.map((item, index) => (
                            <div
                                key={index}
                                className={`mobile-menu-item ${location.pathname === item.path ? 'active' : ''}`}
                                onClick={() => handleItemClick(item)}
                            >
                                <div className={item.isPremium ? "menu-icon-mobile-premium" : "menu-icon-mobile"}>
                                    {item.icon}
                                </div>
                                <span className={item.isPremium ? "menu-label-premium" : "menu-label"}>
                                    {item.label}
                                </span>
                            </div>
                        ))}
                    </div>
                    {/*<div className="join-discord-button">*/}
                    {/*    <DiscordIcon/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

export default MobileChatSlideMenu;