import React, {useState} from 'react';
import './NewMainPage.css';
import {NewCharacterList} from "../components/MainMenuPage/NewCharacterList";
import {HeaderMainMenu} from "../components/MainMenuPage/Header";
import {CharacterContextProvider} from '../components/Context/CharacterContext';
import {MobileNavigation} from "../components/MainMenuPage/MobileNavigation";
import {Link} from "react-router-dom";

export const MenuPageUpdated = () => {
    const [searchText, setSearchText] = useState('');
    const [realChars, setRealChars] = useState([]);
    const [searchCharacters, setSearchCharacters] = useState([]);

    return (
        <CharacterContextProvider value={{realChars, setRealChars}}>
            <div className="MainNewPage">
                <MobileNavigation searchText={searchText} setSearchText={setSearchText}
                                  setSearchCharacters={setSearchCharacters}/>

                <HeaderMainMenu searchText={searchText} setSearchText={setSearchText}
                                setSearchCharacters={setSearchCharacters}/>

                <div className="CharacterListH">
                    <NewCharacterList
                        ownCharacters={"Created Characters"}
                        search={searchText}
                        setSearchText={setSearchText}
                        tag={"All"}
                        searchCharacters={searchCharacters}
                        searchText={searchText}
                        setSearchCharacters={setSearchCharacters}
                    />
                </div>

                <div className="bottom-bar">
                    <div className="bottom-bar-left">
                        <div>
                            <Link to="/legal/other" className="bottom-bar-link">
                                18 U.S.C. § 2257 Exemption
                            </Link>
                        </div>
                        <div>
                            <Link to="/privacy-policy" className="bottom-bar-link">
                                Privacy Policy
                            </Link>
                        </div>
                        <div>
                            <Link to="/affiliate" className="bottom-bar-link">
                                Become an Affiliate
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </CharacterContextProvider>
    );
};