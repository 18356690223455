import React, { useState } from 'react';
import './PauseSubscriptionScreen.css';
import {ReactComponent as CloseIcon} from "../../components/MainMenuPage/icons/cross.svg";
import {ReactComponent as CheckMark} from "./files/checkmark.svg";

const PauseSubscriptionScreen = ({isOpen, onClose, pauseSubscription}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isPausing, setIsPausing] = useState(false);
    const [pauseSuccess, setPauseSuccess] = useState(false);

    const pauseOptions = [
        { id: 1, duration: '1 Week', days: 7 },
        { id: 2, duration: '2 Weeks', days: 14 },
        { id: 3, duration: '1 Month', days: 30 }
    ];

    const handleOptionSelect = (optionId) => {
        setSelectedOption(optionId);
    };

    const pauseSubscriptionMain = async () => {
        setIsPausing(true);
        // Convert days to milliseconds timestamp (days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        const pauseDurationMs = pauseOptions[selectedOption - 1].days * 24 * 60 * 60 * 1000;
        // Calculate end date timestamp (current time + duration)
        const pauseEndTimestamp = Date.now() + pauseDurationMs;
        await pauseSubscription(pauseEndTimestamp);
        setIsPausing(false);
        setPauseSuccess(true);
    }
       
    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" style={{zIndex: 100001}}>
            <div className="modal-content-notification pause-subscription-modal">
                <div className='notification-label'>
                    Pause Subscription
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    Select how long you'd like to pause your subscription. Your benefits will be temporarily suspended during this period.
                </div>

                <div className="pause-options-container">
                    {pauseOptions.map((option) => (
                        <div 
                            key={option.id}
                            className={`pause-option ${selectedOption === option.id ? 'selected' : ''}`}
                            onClick={() => handleOptionSelect(option.id)}
                        >
                            <div className="pause-option-header">
                                <span className="pause-duration">{option.duration}</span>
                                {selectedOption === option.id && (
                                    <div className="pause-checkmark">
                                        <CheckMark />
                                    </div>
                                )}
                            </div>
                            <div className="pause-description">
                                Pause your subscription for {option.duration.toLowerCase()}
                            </div>
                        </div>
                    ))}
                </div>

                {pauseSuccess ? (
                    <div className="pause-success-message">
                        <CheckMark />
                        <span>Your subscription has been paused successfully!</span>
                    </div>
                ) : (
                    <div className="pause-actions">
                        <button 
                            onClick={onClose} 
                            className="notification-button-main"
                            style={{backgroundColor: '#656565',maxWidth: '60%', marginTop: '0'}}
                        >
                            Cancel
                        </button>
                        <button 
                            onClick={pauseSubscriptionMain} 
                            className={`notification-button-main ${!selectedOption ? 'disabled' : ''}`}
                            disabled={!selectedOption || isPausing}
                            style={{ maxWidth: '40%', marginTop: '0'}}
                        >
                            {isPausing ? 'Pausing...' : 'Pause Subscription'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PauseSubscriptionScreen;
