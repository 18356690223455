import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import EmailVerification from "../../components/Forms/EmailVerification";
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {getUserAccessToken, getUserID, userIDExists} from "../../App";
import {ReactComponent as GoBackIcon} from "./icons/backIcon.svg";
import AdvancedStepOne from "./AdvancedSteps/AdvancedStepOne";
import AdvancedStepTwo from "./AdvancedSteps/AdvancedStepTwo";
import AdvancedStepThree from "./AdvancedSteps/AdvancedStepThree";
import AdvancedStepFive from "./AdvancedSteps/AdvancedStepFive";
import AdvancedStepSix from "./AdvancedSteps/AdvancedStepSix";
import AdvancedStepFour from "./AdvancedSteps/AdvancedStepFour";
import AdvancedStepSeven from "./AdvancedSteps/AdvancedStepSeven";
import AdvancedStepLoras from "./AdvancedSteps/AdvancedStepLoras";
import {useAdvancedCharacterCreation} from "./AdvancedCharacterContext";
import LoadingScreen from "../../components/LoadingEvents/EpicLoadingScreen";
import {useNavigate} from "react-router-dom";
import AdvancedStepOneHalf from "./AdvancedSteps/AdvancedStepOneHalf";
import NavigationButton from "./SubMain/RenderButtonACC";
import {ProgressBar} from "./SubMain/ProgressBarACC";
import AdvancedStepVoiceAdvanced from "./AdvancedSteps/AdvancedStepVoiceAdvanced";
import {useTranslation} from "react-i18next";
import AdvancedReviewScreen from "../../components/Modals/FinalReviewScreens/AdvancedReviewScreen";
import TopTimer from "../../components/Notifications/TopBanners/TopTimer";
import {useTimer} from "../../components/Context/TimerContext";
import AdvancedSuccessModal from "../SimpleCharacterCreation/screens/AdvancedSuccessModal";
import CharWarningModalCentralized from "../../components/Modals/CharWarningModalCentralized";
import CreateCharPleaseLogin from "../../components/Modals/CreateCharPleaseLogin";
import RanOutOfTokens from "../../components/Notifications/Upsells/RanOutOfTokensModal";
import {MobileNavigation} from "../../components/MainMenuPage/MobileNavigation";
//ACC means advanced char creator lol
const Step = ({
                  component: StepComponent,
                  title,
                  subtitle,
                  index,
                  currentStep,
                  setCurrentStep,
                  scrollToStep,
                  totalSteps,
                  steps, returnToStep
              }) => {

    //TODO: breaks on char creator

    // const location = useLocation();
    //
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location.pathname]);

    const {language} = useProfileData();

    const mapLanguageCodeToEnglishName = (code) => {
        return languageCodeToEnglishName[code] || code;
    };

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    };

    let correctLanguage = mapLanguageCodeToEnglishName(language);

    const [ref, inView] = useInView({
        threshold: 0.1,
        rootMargin: '50px',
        triggerOnce: false
    });

    useEffect(() => {
        if (inView) {
            setCurrentStep(index);
        }
    }, [inView, index, setCurrentStep]);

    useEffect(() => {
        if (inView) {
            requestAnimationFrame(() => {
                setCurrentStep(index);
            });
        }
        //eslint-disable-next-line
    }, [inView]);

    const {
        advancedCharData,
        useAiGeneration,
        prompt,
        imageFile,
        showPrompt, clearAdvancedOptions,
        setGeneratedProfileImage
    } = useAdvancedCharacterCreation();

    const handleNext = () => {
        if (index < totalSteps - 1) {
            scrollToStep(index + 1);
        }
    };

    const handlePrevious = () => {
        if (index > 0) {
            scrollToStep(index - 1);
        }
    };

    const isLastStep = index === totalSteps - 1;
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isProfileImageGenerating, setIsProfileImageGenerating] = useState(false)
    const navigate = useNavigate();
    const [advancedSuccessModal, setAdvancedSuccessModal] = useState(false)
    const [identityName, setIdentityName] = useState('');
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [createCharLoginModal, setCreateCharLoginModal] = useState(false);
    const [tokenErrorModal, setTokenErrorModal] = useState(false);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    const handleAdvancedCharacterCreator = async () => {

        if (!isLoggedIn) {
            setCreateCharLoginModal(true);
            return;
        }

        try {
            setIsLoading(true);

            const response = await fetch('https://api.elyza.app/v1/advanced-character-creator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken(),
                },
                body: JSON.stringify({
                    gender: advancedCharData.gender,
                    mainStyle: advancedCharData.style,
                    // advancedAnimeStyle: advancedCharData.specialStyle,
                    isNSFW: advancedCharData.nsfw,
                    isPublic: advancedCharData.isPublic,
                    charName: advancedCharData.charName,
                    description: advancedCharData.description,
                    appearance: advancedCharData.appearance,
                    personality: advancedCharData.personality,
                    scenario: advancedCharData.scenario,
                    firstMessage: advancedCharData.dialogue,
                    lora: advancedCharData.loraAirUrl,
                    triggers: advancedCharData.loraTrainedWords,
                    profileURL: advancedCharData.profileURL,
                    specialSetting: advancedCharData.specialCase,
                    languageSelection: advancedCharData.languageSelection,
                    voiceSelection: advancedCharData.voiceSelection,
                    voiceName: advancedCharData.clonedVoiceName,
                    voiceSample: advancedCharData.clonedVoiceURL
                }),
            });

            const data = await response.json();

            if (response.status === 403) {
                setErrorMessage('Content violates our policies');
                setIsWarningModalOpen(true);
                setIsLoading(false);
                return;
            }

            if (!response.ok) {
                setIsWarningModalOpen(true);
                setIsLoading(false);
                setErrorMessage('General Error - Failed To Create');
                return;
            }

            if (data.identityName) {
                setAdvancedSuccessModal(true);
                setIdentityName(data.identityName);
            } else {
                setIsWarningModalOpen(true);
                setIsLoading(false);
                setErrorMessage('General Error - No ID returned');
            }
        } catch (error) {
            setIsWarningModalOpen(true);
            setIsLoading(false);
            setErrorMessage('General Error');
        } finally {
            setIsLoading(false);
        }
    };

    const [internalLimit, setInternalLimit] = useState(false);

    const handleStartChatting = async () => {
        if (internalLimit) return;
        setInternalLimit(true);

        try {
            const id = await getUserID();
            if (!identityName) {
                console.error('No identity name available');
                // Handle this case (e.g., show an error message to the user)
                return;
            }

            await fetch('https://api.elyza.app/v1/create-character', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: await getUserAccessToken(),
                },
                body: JSON.stringify({
                    userId: id,
                    identityName: identityName,
                    creatorId: "",
                    language: correctLanguage
                }),
            });
            navigate(`/chat/${identityName}`, { state: { autoSelect: true } });

        } catch (error) {
            console.error('Error starting chat:', error);
        } finally {
            setInternalLimit(false);
            clearAdvancedOptions();
        }
    };

    const createAdvancedCharacter = () => {
        handleAdvancedCharacterCreator();
    }

    const handleFinish = () => {
        if (!isLoggedIn) {
            setCreateCharLoginModal(true);
        } else {
            setShowReviewModal(true)
        }
    }

    const {t} = useTranslation()

    return (
        <>
            {/*<LoadingModal isOpen={isLoading}/>*/}

            <RanOutOfTokens
                isOpen={tokenErrorModal}
                onClose={() => setTokenErrorModal(false)}
            />

            <motion.div
                ref={ref}
                initial={{opacity: 0, y: 0}}
                animate={inView ? {opacity: 1, y: 0} : {opacity: 0, y: -50}}
                transition={{duration: 0.5}}
                className="step-container"
            >

                <div className="character-creation-header">
                    <h1 className="main-title">{t('CharacterCreatorTitle')}</h1>
                    <p className="main-description">{t('CharacterCreatorDescription')}</p>
                </div>

                <ProgressBar currentStep={currentStep} totalSteps={steps.length}/>

                <div className="step-content">

                    <h2 className="step-title">{title}</h2>
                    <h3 className="step-subtitle">{subtitle}</h3>

                    <StepComponent/>

                    <motion.div
                        className="navigation-buttons"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.5}}
                    >
                        <button
                            onClick={handlePrevious}
                            disabled={index === 0}
                            className="nav-button prev-button"
                        >
                            <GoBackIcon/>
                        </button>

                        {NavigationButton({
                            isLastStep,
                            handleNext,
                            handleFinish,
                            useAiGeneration,
                            showPrompt,
                            imageFile,
                            prompt,
                            advancedCharData,
                            setGeneratedProfileImage,
                            isProfileImageGenerating,
                            setIsProfileImageGenerating,
                            isLoggedIn,
                            tokenErrorModal, setTokenErrorModal
                        })}
                    </motion.div>
                </div>
            </motion.div>

            <AdvancedReviewScreen
                isOpen={showReviewModal}
                onClose={() => setShowReviewModal(false)}
                onConfirm={createAdvancedCharacter}
                onReturnToStep={returnToStep}
            />

            <LoadingScreen
                isLoading={isLoading}
            />

            <AdvancedSuccessModal
                isOpen={advancedSuccessModal}
                onClose={() => setAdvancedSuccessModal(false)}
                characterName={advancedCharData.charName}
                startChatting={handleStartChatting}
                clearAdvancedOptions={clearAdvancedOptions}
            />

            <CharWarningModalCentralized
                isOpen={isWarningModalOpen}
                onClose={() => setIsWarningModalOpen(false)}
                message={errorMessage}
            />

            <CreateCharPleaseLogin
                isOpen={createCharLoginModal}
                characterName={advancedCharData.charName}
                onClose={() => setCreateCharLoginModal(false)}
            />
        </>
    );
};

const AdvancedCharacterCreation = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const {profileData} = useProfileData();
    const containerRef = useRef(null);
    const {loras} = useAdvancedCharacterCreation();

    const loraItems = loras?.regular || [];

    const returnToStep = (stepName) => {
        //TODO: forgor language :C can we even make this work?
        if (stepName === t('Scenario')) {
            stepName = t('SetTheScene');
        } else if (stepName === t('FirstMessage')) {
            stepName = t('SparkThe');
        } else if (stepName === t('GenderSetting') || stepName === t('SafetySetting')) {
            stepName = t('WelcomeToThe');
        } else if (stepName === t('PrivacySetting') || stepName === t('StyleSetting')) {
            stepName = t('ContinueWith');
        } else if (stepName === t('CharacterName') || stepName === t('Description') || stepName === t('Age')) {
            stepName = t('Foundation');
        } else if (stepName === t('LanguageSelection')) {
            stepName = t('VoiceSelection');
        }

        const stepIndex = steps.findIndex(step =>
            step.title.toLowerCase().includes(stepName.toLowerCase())
        );
        if (stepIndex !== -1) {
            scrollToStep(stepIndex);
            setCurrentStep(stepIndex);
        }
    };

    const {t} = useTranslation();

    const steps = [
        {
            component: AdvancedStepOne,
            title: t("WelcomeTitle"),
            subtitle: t("WelcomeSubtitle")
        },
        {
            component: AdvancedStepOneHalf,
            title: t("ContinueCreationTitle"),
            subtitle: t("ContinueCreationSubtitle")
        },
        {
            component: AdvancedStepTwo,
            title: t("FoundationTitle"),
            subtitle: t("FoundationSubtitle")
        },
        {
            component: AdvancedStepVoiceAdvanced,
            title: t("VoiceTitle"),
            subtitle: t("VoiceSubtitle")
        },
        ...(loraItems.length > 0 ? [{
            component: AdvancedStepLoras,
            title: t("LoraTitle"),
            subtitle: t("LoraSubtitle")
        }] : []),
        {
            component: AdvancedStepThree,
            title: t("PersonalityTitleAdvanced"),
            subtitle: t("PersonalitySubtitleAdvanced")
        },
        {
            component: AdvancedStepFour,
            title: t("AppearanceTitle"),
            subtitle: t("AppearanceSubtitle")
        },
        {
            component: AdvancedStepFive,
            title: t("SceneTitle"),
            subtitle: t("SceneSubtitle")
        },
        {
            component: AdvancedStepSix,
            title: t("ConversationTitle"),
            subtitle: t("ConversationSubtitle")
        },
        {
            component: AdvancedStepSeven,
            title: t("VisualizeTitle"),
            subtitle: t("VisualizeSubtitle")
        },
    ];


    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    useEffect(() => {
        if (isLoggedIn && !profileData.is_verified) {
            setIsEmailVerificationModalOpen(true);
        }
    }, [isLoggedIn, profileData.is_verified]);

    const scrollToStep = (index) => {
        if (containerRef.current && containerRef.current.children[index]) {
            const isMobile = window.innerWidth <= 680;
            const element = containerRef.current.children[index];
            const elementRect = element.getBoundingClientRect();
            const offset = isMobile ? window.innerHeight * 0.10 : 55; // 15% of viewport height on mobile, 55px on desktop

            const scrollPosition = window.pageYOffset + elementRect.top - offset;

            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    };

    const {timerExpired} = useTimer();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPaddingTop = () => {
        const isMobile = windowWidth <= 680;

        if (timerExpired) {
            return isMobile ? "145px" : "55px";  // timerExpiredMobile : timerExpiredDesktop
        } else {
            return isMobile ? "195px" : "95px";  // timerActiveMobile : timerActiveDesktop
        }
    };

    return (
        <>
            {!timerExpired ? (
                <div className="top-timer-container-main-pricing"
                     style={{display: timerExpired !== false ? "none" : 'block',  zIndex: '1003', }}
                >
                    <TopTimer/>
                </div>
            ) : null}

            <HeaderMainMenu/>
            <div className="container-char-creator">

                <AnimatePresence>
                    {isEmailVerificationModalOpen && (
                        <EmailVerification
                            isOpen={isEmailVerificationModalOpen}
                            onClose={() => setIsEmailVerificationModalOpen(false)}
                        />
                    )}
                </AnimatePresence>
                <div ref={containerRef} className="steps-container"
                     style={{ paddingTop: getPaddingTop() }}
                >
                    {steps.map((step, index) => (
                        <Step
                            key={index}
                            {...step}
                            index={index}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            scrollToStep={scrollToStep}
                            totalSteps={steps.length}
                            steps={steps}
                            returnToStep={returnToStep}
                        />
                    ))}
                </div>
            </div>

            <MobileNavigation/>

        </>
    );
};

export default AdvancedCharacterCreation