import React, { useEffect, useState } from 'react';
import '../../components/Notifications/ModalAndNotifications.css';
import './FinalModal.css';
import {ReactComponent as CloseIcon} from "../../components/MainMenuPage/icons/cross.svg";
// import {useTranslation} from "react-i18next";
import { getUserAccessToken, getUserID } from "../../App";
import ConfirmCancelletionScreen from './ConfirmCancelletionScreen';

const FinalModal = ({isOpen, onClose, subscriptionID}) => {
    // const {t} = useTranslation();
    const [finalMessage, setFinalMessage] = useState(null);
    const [showConfirmCancellation, setShowConfirmCancellation] = useState(false);

    const cancelPlan = async () => {
        try {
            const result = await fetch('https://api.elyza.app/v1/handle-cancellation-funnel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    operation: 'cancelSubscription',
                    subscriptionId: subscriptionID
                })
            });
            const data = await result.json();
            
            if (data.success) {
                // Show confirmation screen instead of closing
                setShowConfirmCancellation(true);
            } else {
                console.error('Error cancelling subscription:', data.error);
                onClose();
            }
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            onClose();
        }
    }
        
    useEffect(() => {
        const fetchFinalMessage = async () => {
            try {
                const response = await fetch('https://api.elyza.app/v1/final-message', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: await getUserAccessToken(),
                    },
                    body: JSON.stringify({
                        user_id: await getUserID()
                    })
                });
                const data = await response.json();
                setFinalMessage(data);
            } catch (error) {
                console.error('Error fetching final message:', error);
            }
        };

        if (isOpen) {
            fetchFinalMessage();
            // Reset confirmation screen state when modal is opened
            setShowConfirmCancellation(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    // Show confirmation screen if cancellation was successful
    if (showConfirmCancellation) {
        return <ConfirmCancelletionScreen 
            isOpen={true} 
            onClose={onClose} 
        />;
    }

    return (
        <div className="modal-backdrop"
            style={{zIndex: 10002}}
        >
            <div className="modal-content-notification">
                <div className='notification-label'>
                    Before you go! Please...
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {finalMessage?.character_name} wants to tell you something before you go!
                </div>

                <div className="final-modal-main">
                    <div className="final-modal-column-left">
                        <div className="final-image-main">
                            {finalMessage?.profile_url && (
                                <img
                                    className="final-image"
                                    src={finalMessage.profile_url}
                                    alt={finalMessage.character_name}
                                />
                            )}
                            <span style={{color: '#c4c4c4', fontWeight: '700', fontSize: '1.15em'}}>
                                {finalMessage?.character_name}
                            </span>
                        </div>
                    </div>

                    <div className="final-modal-column-right">
                        <div className="final-message">
                            <span>
                                {finalMessage?.message}
                            </span>
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px'}}>
                    <button onClick={onClose} className="notification-button-main">Go Back</button>
                    <button onClick={() => cancelPlan()}
                            style={{width: '50%', backgroundColor: '#656565'}}
                            className="notification-button-main">Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FinalModal;