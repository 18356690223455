import React, {useState, useEffect, useRef} from 'react';
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';
import {ReactComponent as OpenMenuButton} from "./CustomizationIcons/OpenMenuButton.svg";
import {ReactComponent as CirclePlusIcon} from "./CustomizationIcons/CirclePlusIcon.svg";
import {ReactComponent as EditIcon} from "./CustomizationIcons/EditIcon.svg";
import {ReactComponent as DeleteIcon} from "./CustomizationIcons/DeleteIcon.svg";
import './CustomizationModal.css';
import './SmallerModalCustomization.css';
import {getUserID} from "../../../App";
import {
    // addMemoryFragment,
    // addPersonalityProfile,
    editMemoryFragment,
    editPersonality,
    getUpdatedMemoriesAndPersonalities,
    deletePersonality,
    deleteMemoryFragment,
    selectMemoryFragments,
    selectPersonality
} from "./CustomizationFunctions";
import AddCustomization from "./AddCustomization";
import DeleteConfirmationModal from "./ConfirmDeletePortal";
import EditCustomizationModal from "./EditCustomizationModal";
import {useTranslation} from "react-i18next";
import UniversalFilterModal from "../../Modals/UniversalFilterModal";

const SmallerModalCustomization = ({isOpen, onClose, onEdit, onDelete}) => {
    const modalRef = useRef(null);

    const {t} = useTranslation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="smaller-modal-content" ref={modalRef}>
            <div className="edit-delete-container">
                <button onClick={onEdit} className="custom-button edit-icon-bt">
                    <div className='custom-button-icon'>
                        <EditIcon/>
                    </div>
                    {t('Edit')}
                </button>
                <div className="button-separator"/>
                <button onClick={onDelete} className="custom-button delete-icon-bt">
                    <div className='custom-button-icon'>
                        <DeleteIcon/>
                    </div>
                    {t('Delete')}
                </button>
            </div>
        </div>
    );
};

const ItemGrid = ({items, type, onItemClick, onItemEdit, onItemDelete, onAddItem}) => {
    const [openMenuId, setOpenMenuId] = useState(null);

    const handleOpenMenuClick = (event, id) => {
        event.stopPropagation();
        setOpenMenuId(openMenuId === id ? null : id);
    };

    const handleItemClick = (event, id) => {
        if (event.target.tagName.toLowerCase() !== 'h2' && event.target.tagName.toLowerCase() !== 'textarea') {
            onItemClick(id);
        }
    };

    const {t} = useTranslation();

    return (
        <div className="user-profile-grid">
            {items.map((item) => (
                <div
                    key={item.id}
                    className={`profile-box ${item.selected ? 'selected' : ''}`}
                    onClick={(event) => handleItemClick(event, item.id)}
                >
                    <div className='profile-head-header'>
                        <h2> {item.name}</h2>
                        <OpenMenuButton
                            className="open-menu-button"
                            onClick={(event) => handleOpenMenuClick(event, item.id)}
                        />
                        {openMenuId === item.id && (
                            <SmallerModalCustomization
                                isOpen={true}
                                onClose={() => setOpenMenuId(null)}
                                onEdit={(event) => {
                                    event.stopPropagation();
                                    onItemEdit(item.id);
                                }}
                                onDelete={(event) => {
                                    event.stopPropagation();
                                    onItemDelete(item.id);
                                }}
                            />
                        )}
                    </div>
                    <span className='profile-box-bottom-text'>{item.description}</span>
                </div>
            ))}
            {items.length < 6 && (
                <div className="profile-box new-profile-box" onClick={onAddItem}>
                    <div className="new-profile-content">
                        <span className="plus-icon">
                            <CirclePlusIcon/>
                        </span>
                        <p>{t('Add')} {type === 'personalities' ? t('Profile') : t('Memory')}
                            {/*{t('Card')}*/}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};


const CustomizationModal = ({isOpen, onCancel}) => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addingItemType, setAddingItemType] = useState(null);
    const [memoriesAndPersonalities, setMemoriesAndPersonalities] = useState({
        memories: [],
        personalities: []
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleItemClick = (id, type) => {
        setMemoriesAndPersonalities(prev => ({
            ...prev,
            [type]: prev[type].map(item => {
                if (type === 'personalities') {
                    // For personalities, toggle selection and ensure only one is selected
                    return {
                        ...item,
                        selected: item.id === id ? !item.selected : false
                    };
                } else {
                    // For memories, toggle the selected state
                    return {
                        ...item,
                        selected: item.id === id ? !item.selected : item.selected
                    };
                }
            })
        }));
        setHasChanges(true);
    };

    const fetchMemoriesAndPersonalities = async (identityName) => {
        setIsLoading(true);
        try {
            // Fetch the updated list of memories and personalities from the server
            const updatedMemoriesAndPersonalities = await getUpdatedMemoriesAndPersonalities(identityName);
            
            // Update the state
            setMemoriesAndPersonalities(updatedMemoriesAndPersonalities);
        } catch (error) {
            console.error("Error fetching memories and personalities:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;
        
        const fetchData = async () => {
            if (!isOpen) return;
            
            try {
                const identityName = window.location.pathname.substring(6);
                
                if (isMounted) {
                    await fetchMemoriesAndPersonalities(identityName);
                }
            } catch (error) {
                console.error("Error in fetchData:", error);
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };
        
        fetchData();
        
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, [isOpen]);

    const [showWarningModal, setShowWarningModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleItemEdit = async (id, updates) => {
        const type = id.startsWith('pers') ? 'personalities' : 'memories';
        const existingItem = memoriesAndPersonalities[type].find(item => item.id === id);
        const updatedItem = {
            ...existingItem,
            name: updates.name,
            description: updates.description
        };
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);

        try {
            let result;
            if (type === 'personalities') {
                result = await editPersonality(userId, identityName, id, updatedItem.name, updatedItem.description);
            } else {
                result = await editMemoryFragment(userId, identityName, id, updatedItem.name, updatedItem.description);
            }

            if (result.error === 'Prohibited Content') {
                setShowWarningModal(true);
                setErrorMessage('Content violates our policies');
                return;
            }

            if (result.error) {
                console.error('Error updating item:', result.error);
                return;
            }

            setMemoriesAndPersonalities(prev => ({
                ...prev,
                [type]: prev[type].map(item => item.id === id ? updatedItem : item)
            }));

            setEditModalOpen(false);
            setCurrentItem(null);
        } catch (error) {
            console.error('Error updating item:', error);
        } finally {
            await fetchMemoriesAndPersonalities(identityName);
        }
    };

    const handleItemDelete = (id) => {
        setCurrentItem(id);
        setDeleteConfirmOpen(true);
    };

    const confirmDelete = async () => {
        const type = currentItem.startsWith('mem') ? 'memories' : 'personalities';
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);

        try {
            let result;
            if (type === 'memories') {
                result = await deleteMemoryFragment(userId, identityName, currentItem);
            } else {
                result = await deletePersonality(userId, currentItem);
            }

            if (result.error) {
                console.error(`Error deleting ${type}:`, result.error);
                // You might want to show an error message to the user here
                return;
            }

            setMemoriesAndPersonalities(prev => ({
                ...prev,
                [type]: prev[type].filter(item => item.id !== currentItem)
            }));
        } catch (error) {
        } finally {
            setDeleteConfirmOpen(false);
            setCurrentItem(null);
            await fetchMemoriesAndPersonalities(identityName);
        }
    };

    const handleAddItem = (type) => {
        setAddingItemType(type);
        setAddModalOpen(true);
    };

    const handleSaveNewItem = async () => {
        const identityName = window.location.pathname.substring(6);
        try {
            setAddModalOpen(false);
            setAddingItemType(null);

        } catch (error) {
            console.error('Error adding item:', error);
        } finally {
            await fetchMemoriesAndPersonalities(identityName);
        }
    };

    const handleSave = async () => {
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);

        try {
            // Handle personality selection
            const selectedPersonality = memoriesAndPersonalities.personalities.find(p => p.selected);
            if (selectedPersonality) {
                await selectPersonality(userId, selectedPersonality.id);
            }

            // Handle memory fragment selection
            const selectedMemoryIds = memoriesAndPersonalities.memories
                .filter(m => m.selected)
                .map(m => m.id);
            await selectMemoryFragments(userId, identityName, selectedMemoryIds);

            // const updatedMemoriesAndPersonalities = await getUpdatedMemoriesAndPersonalities(userId, identityName);
            // setMemoriesAndPersonalities(updatedMemoriesAndPersonalities);
            setHasChanges(false);
            onCancel();
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };

    const {t} = useTranslation();

    if (!isOpen) return null;

    return (
        <>
            <div className="main-space-finder-modal">
                <div className="app-modal-content-response-settings"
                     onClick={(e) => e.stopPropagation()}>
                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">{t('CustomizationSettings')}</div>
                        <div className="close-modal-icon" onClick={onCancel}><CloseIcon/></div>
                    </div>

                    <div className="main-elyza-text-response-settings">
                        {t('SuperChargeExperience')}
                    </div>

                    {isLoading ? (
                        <div className="loading-container">
                            <p>{t('Loading customization data...')}</p>
                        </div>
                    ) : (
                        <>
                            <div className="line-container">
                                <span className="text-line-container">{t('UserProfiles')}</span>
                                <div className="line"></div>
                            </div>

                            <div className="main-elyza-text-response-settings">
                                {t('CustomizationSettingsText1')}
                            </div>
                            {/*//TODO fetch if empty(usually happens on when switching between mobile and desktop)*/}
                            <ItemGrid
                                items={memoriesAndPersonalities !== null ? memoriesAndPersonalities.personalities : []}
                                type="personalities"
                                onItemClick={(id) => handleItemClick(id, 'personalities')}
                                onItemEdit={(id) => {
                                    setCurrentItem(memoriesAndPersonalities.personalities.find(item => item.id === id));
                                    setEditModalOpen(true);
                                }}
                                onItemDelete={handleItemDelete}
                                onAddItem={() => handleAddItem('personalities')}
                            />

                            <div className="line-container">
                                <span className="text-line-container">{t('MemoryFragments')}</span>
                                <div className="line"></div>
                            </div>

                            <div className="main-elyza-text-response-settings">
                                {t('CustomizationSettingsText2')}
                            </div>

                            <ItemGrid
                                items={memoriesAndPersonalities !== null ? memoriesAndPersonalities.memories : []}
                                type="memories"
                                onItemClick={(id) => handleItemClick(id, 'memories')}
                                onItemEdit={(id) => {
                                    setCurrentItem(memoriesAndPersonalities.memories.find(item => item.id === id));
                                    setEditModalOpen(true);
                                }}
                                onItemDelete={handleItemDelete}
                                onAddItem={() => handleAddItem('memories')}
                            />

                            <div className="a-straight-line-3"></div>

                            <div className="footer-button-container">
                                <button className="need-help-button" style={{backgroundColor: '#757575'}} onClick={onCancel}>
                                    {t('Cancel')}
                                </button>
                                <button className="save-settings-button" onClick={handleSave} disabled={!hasChanges}>
                                    {t('save')}
                                </button>
                            </div>
                        </>
                    )}
                </div>

                {editModalOpen && currentItem && (
                    <EditCustomizationModal
                        isOpen={editModalOpen}
                        onClose={() => {
                            setEditModalOpen(false);
                            setCurrentItem(null);
                        }}
                        item={currentItem}
                        onSave={(updatedItem) => handleItemEdit(currentItem.id, updatedItem)}
                        type={currentItem.id.startsWith('pers') ? 'personality' : 'memory'}
                    />
                )}

                {deleteConfirmOpen && (
                    <DeleteConfirmationModal
                        isOpen={deleteConfirmOpen}
                        onClose={() => setDeleteConfirmOpen(false)}
                        onConfirm={confirmDelete}
                    />
                )}

                {addModalOpen && (
                    <AddCustomization
                        isOpen={addModalOpen}
                        onClose={() => {
                            setAddModalOpen(false);
                            setAddingItemType(null);
                        }}
                        onSaveNew={handleSaveNewItem}
                        type={addingItemType}
                    />
                )}

                <UniversalFilterModal
                    isOpen={showWarningModal}
                    onClose={() => setShowWarningModal(false)}
                    message={errorMessage}
                />

            </div>
        </>
    );
};

export default CustomizationModal;