import React from 'react';
import './ConfirmCancelletionScreen.css';
import {ReactComponent as CloseIcon} from "../../components/MainMenuPage/icons/cross.svg";

const ConfirmCancelletionScreen = ({isOpen, onClose}) => {

    const handleClose = () => {
        onClose();
        window.location.reload();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" style={{zIndex: 10005}}>
            <div className="modal-content-notification confirm-cancellation-modal">
                <div className='notification-label'>
                    Subscription Cancelled
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                Your subscription has been successfully cancelled
                </div>

                    <button onClick={handleClose}
                    style={{width: '100%', marginTop: '20px'}}
                    className="notification-button-main">
                        OK
                    </button>
        
            </div>
        </div>
    );
};

export default ConfirmCancelletionScreen;
